import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'

import { TagsV1 } from '../config'
import { ITagComboId } from '../typings/tag'
interface Props {
  combo: ITagComboId
  isLink?: boolean
  labelClassName?: string
}

export const TagComboV1Label = observer(({ combo, isLink, labelClassName }: Props) => {
  const { i18n } = useTranslation()
  const isZh = i18n.resolvedLanguage === 'zh'
  const attIds = combo.filter((id) => TagsV1[id]?.isAtt)
  const ids = combo.filter((id) => !attIds.includes(id))

  return (
    <div className="flex">
      <LinkWrapper combo={combo} isLink={isLink}>
        <p className={cn('text-primary-active', labelClassName)}>
          #
          {attIds
            .map((id) => (isZh ? TagsV1[id]?.cnName : TagsV1[id]?.enName))
            .concat(ids.map((id) => (isZh ? TagsV1[id]?.cnName : TagsV1[id]?.enName)))
            .join('_')}
        </p>
      </LinkWrapper>
    </div>
  )
})

const LinkWrapper = observer(({ children, isLink, combo }: { children: ReactNode } & Props) => {
  if (isLink) {
    return (
      <Link href={`/v1/explore/prompts/${encodeURIComponent(JSON.stringify([combo]))}`}>
        {children}
      </Link>
    )
  }

  return <>{children}</>
})
