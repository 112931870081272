import { cn } from '@my/magic-ui/src'
import { Button } from 'app/components/Button'
import { useForm } from 'react-hook-form'

import { FormField } from './components/FormField'
import { useChangePassword } from './hooks/useChangePassword'

interface ChangePasswordFormProps {
  className?: string
  afterChange: () => void
}

interface FormData {
  password: string
  confirmPassword: string
}

export const ChangePasswordForm = ({ className, afterChange }: ChangePasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>()

  const { onChangePassword, loading$ } = useChangePassword()

  const onSubmit = (data: FormData) => {
    onChangePassword({ password: data.password, afterChange })
  }

  return (
    <div className={cn('font-grotesque px-6 py-8', className)}>
      <p className="font-grotesque text-center text-3xl font-bold">Change your password</p>
      <form className="mt-4 space-y-2">
        <FormField
          label="Password"
          type="password"
          placeholder="Enter your password"
          error={errors.password}
          {...register('password', {
            required: 'Password is required',
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters long',
            },
          })}
        />
        <FormField
          label="Confirm Password"
          type="password"
          placeholder="Confirm your password"
          error={errors.confirmPassword}
          {...register('confirmPassword', {
            required: 'Confirm password is required',
            validate: (value) => {
              if (value !== getValues('password')) {
                return 'Passwords do not match'
              }
            },
          })}
        />
      </form>
      <Button
        className="font-grotesque mt-4 w-full bg-[#FFC24C] pb-3 pt-2 text-xl font-bold text-black"
        showArrow={false}
        mode="transparent"
        onClick={handleSubmit(onSubmit)}
        loading={loading$.get()}
        disabled={loading$.get()}
      >
        Change Password
      </Button>
    </div>
  )
}
