import { homeLayoutStore } from 'app/features/home/stores/homeLayout'
import { useUser } from 'app/utils/useUser'
import { useRouter } from 'next/router'

export const useCheckLogin = () => {
  const { isLogin } = useUser()
  const router = useRouter()
  return {
    checkLogin: (redirect = false) => {
      if (isLogin) return true
      else {
        if (redirect) {
          router.push('/sign-in')
        } else {
          homeLayoutStore.openSignInModal()
        }
        return false
      }
    },
  }
}
