import { CommentMetaData_Fragement } from '@my/api/src/routers/comment/graphql/index'
import { getFragmentData } from '@my/supabase/graphql/gql'
import { Spinner } from 'app/components/Spinner'
import { AlertCircle, MessagesSquare } from 'lucide-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CommentData } from './Comment'
import { IComment } from '../../typings/interface'
import { useCommentsContext } from '../context/CommentsContext'
import { useComments } from '../hooks/useComments'

interface CommentsProps {
  parentId: string | null
}

export const CommentList = ({ parentId }: CommentsProps) => {
  const [t] = useTranslation('commentList')
  const { enabled } = useCommentsContext()
  const commentQuery = useComments({ parentId })

  const comments = useMemo(
    () =>
      commentQuery.data?.pages.reduce(
        (prev, curr) =>
          prev.concat(
            (curr.commentsCollection
              ? curr.commentsCollection.edges.map(({ node }) => node)!
              : []) as IComment[]
          ),
        [] as IComment[]
      ) ?? ([] as IComment[]),
    [commentQuery.data?.pages]
  )

  if (!enabled) {
    return (
      <div className="flex flex-col items-center">
        <p className="font-gray-700">{t('login')}</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      {commentQuery.isLoading && (
        <div className="flex justify-center">
          <Spinner />
        </div>
      )}
      {commentQuery.isError && (
        <div className="flex flex-col items-center justify-center gap-3">
          <AlertCircle color="$gray9" />
          <p className="text-gray-700">Unable to load comments.</p>
        </div>
      )}
      {commentQuery.isSuccess && !comments.length && (
        <div className="flex flex-col gap-3">
          <MessagesSquare color="$gray9" />
          <p className="font-gray-700">No comments yet.</p>
        </div>
      )}
      <div className="mt-3.5 flex flex-col gap-3.5">
        {!!comments.length &&
          comments?.map((comment) => (
            <CommentData
              comment={comment}
              key={getFragmentData(CommentMetaData_Fragement, comment).id}
            />
          ))}
      </div>
      {commentQuery.hasNextPage &&
        (commentQuery.isFetching ? (
          <div className="mt-3.5 flex justify-center py-1.5">
            <Spinner />
          </div>
        ) : (
          <div className="mt-5 flex justify-center">
            <p
              className="cursor-pointer text-xs text-gray-700"
              onClick={() => commentQuery.fetchNextPage()}
            >
              View More Comments
            </p>
          </div>
        ))}
      {!!comments.length && !commentQuery.hasNextPage && !parentId && (
        <div className="mt-5 flex justify-center">
          <p className="text-xs text-gray-700">THE END</p>
        </div>
      )}
    </div>
  )
}
