import { observer } from '@legendapp/state/react'
import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import { Dropdown } from 'app/components/Dropdown'
import { showInviteDialog } from 'app/features/ecommerce/InviteDialog/InviteDialog'
import { useUserCredits } from 'app/features/users/hooks/useUserCredits'
import { IconYellowCrown } from 'app/icons/IconYellowCrown'
import { useUser } from 'app/utils/useUser'
import { upperFirst } from 'es-toolkit'
import { useRouter as useNextRouter } from 'next/router'

export const UpgradeMenu = observer(() => {
  const { creditsUsed$, creditsTotal$ } = useUserCredits()
  const { user$ } = useUser()
  const router = useNextRouter()

  if (!user$.data.get()) return null

  return (
    <Dropdown
      zIndex={50}
      trigger={
        <ActionIcon icon={<IconYellowCrown className="h-5 w-5" />} rounded className="md:p-3" />
      }
      enableHover={false}
      placement="bottom-end"
    >
      <div className="w-[231px] rounded-xl border-[1px] border-[#3D3D3D] bg-[#0C0C0C] p-4">
        <div className="flex items-center gap-3">
          <p className="font-grotesque -mt-1 flex-shrink-0 text-lg font-[600] leading-4">
            {upperFirst(user$.data.usersCollection.edges[0]?.node.subscription_tier.get())}
          </p>
          <p className="text-sm font-[500] leading-4 text-white/60">
            {creditsUsed$.get()} / {creditsTotal$.get()} credits
          </p>
        </div>
        <Button
          showArrow={false}
          mode="transparent"
          className="font-grotesque mt-4 h-10 w-full rounded-lg bg-[#FFC24C] text-lg font-bold text-black"
          onClick={() => router.push('/billings')}
        >
          Buy credits
        </Button>
        <Button
          showArrow={false}
          mode="transparent"
          className="font-grotesque mt-2 h-10 w-full rounded-lg text-lg font-bold"
          onClick={() => showInviteDialog()}
        >
          Earn credits
        </Button>
      </div>
    </Dropdown>
  )
})
