import { observable } from '@legendapp/state'
import { observer, useObserve } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { FullscreenSpinner } from 'app/components/Spinner'
import { homeLayoutStore } from 'app/features/home/stores/homeLayout'
import { SocialShare } from 'app/features/socialMedia/SocialShare/SocialShare'
import { api } from 'app/utils/api'
import { COOKIE_INVITER_ID } from 'app/utils/constants/cookie'
import { copyToClipboard } from 'app/utils/hooks/useClipboard'
import { i18nInstance } from 'app/utils/i18n'
import { useUser } from 'app/utils/useUser'
import { X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'

import enMessages from '../locales/en/inviteDialog.json'
import zhMessages from '../locales/zh/inviteDialog.json'

i18nInstance.addResourceBundle('en', 'inviteDialog', enMessages)
i18nInstance.addResourceBundle('zh', 'inviteDialog', zhMessages)

const open$ = observable(false)

export const showInviteDialog = () => open$.set(true)

const shareTitle = 'MyDreamBoy - Pick Your AI Boyfriend'

export const InviteDialogInner = observer(() => {
  const { isLogin } = useUser()
  const [t] = useTranslation('inviteDialog')
  const referrerInfo = api.user.referrerInfo.useQuery(undefined, { enabled: isLogin })

  const link = `${location.origin}?${COOKIE_INVITER_ID}=${referrerInfo.data?.referrerId}`

  const onCopy = () => {
    copyToClipboard(link)
    toast('Link copied to clipboard', {
      position: 'top-center',
      duration: 2000,
      style: {
        background: 'hsl(206, 100%, 50.0%)',
        borderColor: 'hsl(206, 100%, 50.0%)',
        color: '#fff',
        padding: '8px 16px',
      },
    })
  }

  useObserve(() => {
    if (open$.get() && !isLogin) {
      homeLayoutStore.openSignInModal()
    }
  })

  return (
    <Modal isOpen={open$.get() && isLogin} onClose={() => open$.set(false)} showCloseButton={false}>
      <div className="relative w-[90vw] max-w-[480px]">
        {referrerInfo.isLoading ? (
          <div className="h-[480px] w-full">
            <FullscreenSpinner />
          </div>
        ) : (
          <div className="p-4">
            <p className="text-xl font-bold">{t('title')}</p>
            <div className="my-4 h-[1px] w-full bg-gray-300"></div>
            <BlockContainer>
              <SubTitle>{t('spread')}</SubTitle>
              <DescText>{t('spreadDesc')}</DescText>
              <div className="flex items-center gap-3">
                <p className="flex-1 rounded-md bg-gray-200 p-2 text-sm">{link}</p>
                <Button
                  mode="white"
                  showArrow={false}
                  className="w-auto rounded-md p-2 text-sm text-black"
                  onClick={onCopy}
                >
                  {t('copy')}
                </Button>
              </div>
            </BlockContainer>
            <BlockContainer className="mt-5">
              <SubTitle>{t('reward')}</SubTitle>
              <DescText>{t('rewardDesc')}</DescText>
              <div className="my-4 flex flex-col items-center justify-between gap-3 md:flex-row md:gap-5">
                <BoxContainer>
                  <SubTitle>{referrerInfo.data?.referredCount}</SubTitle>
                  <DescText>{t('signed')}</DescText>
                </BoxContainer>
                <BoxContainer>
                  <SubTitle>{(referrerInfo.data?.referredCount ?? 0) * 25}</SubTitle>
                  <DescText>{t('gotten')}</DescText>
                </BoxContainer>
              </div>
            </BlockContainer>
            <BlockContainer>
              <SubTitle>{t('share')}</SubTitle>
              <SocialShare url={link} title={shareTitle} />
            </BlockContainer>
          </div>
        )}
        <ActionIcon
          icon={<X className="h-4 w-4" />}
          className="absolute right-2 top-2"
          onClick={() => open$.set(false)}
        />
      </div>
    </Modal>
  )
})

export const InviteDialogTriggerBtn = ({ className }: { className?: string }) => {
  const [t] = useTranslation('inviteDialog')

  return (
    <Button
      mode="transparent"
      showArrow={false}
      className={cn('text-sm, w-auto rounded-md px-4 py-2 text-sm font-semibold', className)}
      onClick={() => open$.set(true)}
    >
      {t('title')}
    </Button>
  )
}
export const InviteDialog = InviteDialogInner

const BlockContainer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <div className={cn('flex flex-col gap-2', className)}>{children}</div>
}

const SubTitle = ({ children }: { children: React.ReactNode }) => {
  return <p className="text-sm font-bold">{children}</p>
}

const DescText = ({ children }: { children: React.ReactNode }) => {
  return <p className="text-xs font-normal text-gray-800">{children}</p>
}

const BoxContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex w-full flex-1 flex-col items-center justify-center gap-2 rounded-xl border-[1px] border-solid border-gray-300 p-2 text-sm font-bold md:w-[30%]">
      {children}
    </div>
  )
}
