import { observer, useObservable, useSelector } from '@legendapp/state/react'
import { ActionIcon } from 'app/components/ActionIcon'
import { LikeButton } from 'app/components/LikeButton'
import { ReportModal } from 'app/features/users/components/ReportModal'
import { IconExitRightCorner } from 'app/icons/IconExitRightCorner'
import { formatNumber2Str } from 'app/utils/number'
import { Flag } from 'lucide-react'

import {
  usePostDislike,
  usePostLike,
  usePostLikeCount,
  usePostLikeStatus,
} from '../../hooks/usePostInteractions'
import { store$ } from '../context/PostDetailModelContext'
import { showShareDialog } from './PostDetailMenu/components/ShareDialog'
export const PostInteractions = observer(() => {
  const reportModalOpen$ = useObservable(false)

  const post = useSelector(() => store$.post.get())
  const { setPostLike } = usePostLike()
  const { setPostDislike } = usePostDislike()
  const like$ = usePostLikeStatus(post)
  const likes$ = usePostLikeCount(post)

  if (!post) return null

  return (
    <>
      <div className="flex gap-4 py-4">
        <div className="flex w-full cursor-pointer items-center justify-end gap-2">
          <div onClick={() => (like$.get() ? setPostDislike(post) : setPostLike(post))}>
            <LikeButton checked={like$.get()} className="h-5 w-5" />
          </div>
          <p className="text-gray-700">{formatNumber2Str(likes$.get())}</p>
        </div>
        <ActionIcon
          icon={<IconExitRightCorner className="hover:text-primary h-5 w-5 text-gray-700" />}
          square
          onClick={showShareDialog}
        />
        <ActionIcon
          icon={<Flag className="hover:text-primary h-5 w-5 text-gray-700" />}
          square
          onClick={() => reportModalOpen$.set(true)}
          className="-ml-2"
        />
      </div>
      <ReportModal open$={reportModalOpen$} postId={post.id} />
    </>
  )
})
