import { Switch, observer, useComputed } from '@legendapp/state/react'
import { useUser } from 'app/utils/useUser'

import { IconFree } from '../icons/IconFree'

export const IconUserTier = observer(({ className }: { className?: string }) => {
  const { user$ } = useUser()
  const tier = useComputed(() => user$.data.usersCollection.edges[0]?.node.subscription_tier.get())

  return (
    <Switch value={tier}>
      {{
        free: () => <IconFree className={className} />,
        plus: () => (
          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/plus_tier.png"
            className={className}
          />
        ),
        premium: () => (
          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/premium_tier.png"
            className={className}
          />
        ),
        pro: () => (
          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/pro_tier.png"
            className={className}
          />
        ),
        default: () => <IconFree className={className} />,
      }}
    </Switch>
  )
})
