import { observer } from '@legendapp/state/react'
import CustomScrollContainer from 'app/components/CustomScrollContainer'
import { TagComboLabel } from 'app/features/tags/TagComboLabel/TagComboLabel'
import { TagComboV1Label } from 'app/features/tags/TagComboV1/TagComboV1Label'
import { api } from 'app/utils/api'
import { isStandardModelVersion } from 'app/utils/model-version'
import { useEffect } from 'react'

import { store$ } from '../context/PostDetailModelContext'

export const PostDetailTag = observer(() => {
  const model = store$.model.get()

  return (
    <>
      {model.version === 'v1' ? <PostDetailV1Tag /> : <PostDetailV0Tag />}
      <PostDetailUserTag />
    </>
  )
})

const PostDetailV0Tag = observer(() => {
  const post = store$.post.get()

  return (
    <div className="flex flex-wrap gap-2">
      {post.tag_combos?.map((combo, index) => (
        <div className="flex" key={index}>
          {isStandardModelVersion(post.version!) ? (
            <TagComboLabel combo={combo} />
          ) : (
            <TagComboV1Label combo={combo} />
          )}
        </div>
      ))}
    </div>
  )
})

const PostDetailV1Tag = observer(() => {
  const post = store$.post.get()

  if (!post.tag_combos || !post.tag_combos.length) return null

  return (
    <div>
      <p className="mb-2 text-sm text-gray-500">Tags: </p>
      <div className="flex flex-wrap gap-2">
        {post.tag_combos.map((combo, index) => (
          <TagComboV1Label combo={combo} isLink key={index} />
        ))}
      </div>
    </div>
  )
})

const PostDetailUserTag = observer(() => {
  const post = store$.post.get()

  const tagDetailQuery = api.tag.tagDetailByIds.useQuery(
    { ids: post.user_tags ?? [] },
    { enabled: !!post.user_tags?.length }
  )

  useEffect(() => {
    if (tagDetailQuery.isSuccess && tagDetailQuery.data) {
      store$.post.userTagsVO.set(
        tagDetailQuery.data.map((tag) => ({
          id: Number(tag.id),
          label: tag.label ?? '',
          coverImg: tag.coverImg ?? '',
        }))
      )
    }
  }, [tagDetailQuery.data, tagDetailQuery.isSuccess])

  if (!tagDetailQuery.data) return null

  return (
    <div className="mt-2">
      <p className="mb-2 text-sm text-gray-500">User Tags: </p>
      <div className="w-full">
        <CustomScrollContainer trackClassName="bg-gray-300" scrollbarSize={4}>
          <div className="flex pb-2">
            {tagDetailQuery.data.map((tag) => (
              <img
                key={tag.id}
                src={`${process.env.NEXT_PUBLIC_USER_LIBRARY_PREFIX}${tag.coverImg}`}
                className="mx-2 w-11"
              />
            ))}
          </div>
        </CustomScrollContainer>
      </div>
    </div>
  )
})
