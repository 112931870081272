import { observer, useComputed } from '@legendapp/state/react'
import { useUser } from 'app/utils/useUser'
import { motion } from 'framer-motion'
import { User } from 'lucide-react'
import { useState } from 'react'

export const UserAvatar = observer(
  ({
    pid,
    useSelfInfo = false,
    className,
  }: {
    pid?: string
    useSelfInfo?: boolean
    className?: string
  }) => {
    const { avatarUrl$ } = useUser()
    const [isLoading, setIsLoading] = useState(true)

    const finalizeAvatarUrl$ = useComputed(() => {
      const url = useSelfInfo ? avatarUrl$.get() : pid
      return url
        ? url?.startsWith('http')
          ? url
          : `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${url}`
        : null
    })

    return (
      <div className="relative">
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          className={`relative overflow-hidden rounded-full ${className}`}
        >
          {isLoading && (
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-gray-100 via-gray-300 to-gray-100"
              animate={{
                backgroundPosition: ['0%', '100%'],
              }}
              transition={{
                duration: 1.8,
                repeat: Infinity,
                repeatType: 'reverse',
                ease: [0.45, 0.05, 0.55, 0.95],
              }}
              style={{
                backgroundSize: '200% 100%',
              }}
            />
          )}
          {finalizeAvatarUrl$.get() ? (
            <motion.img
              src={finalizeAvatarUrl$.get()!}
              alt="User avatar"
              className="h-full w-full object-cover"
              onLoad={() => setIsLoading(false)}
              initial={{ opacity: 0 }}
              animate={{ opacity: isLoading ? 0 : 1 }}
              transition={{ duration: 0.4, ease: 'easeOut' }}
            />
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <User className="h-full w-full p-1.5 text-gray-400" />
            </motion.div>
          )}
        </motion.div>
      </div>
    )
  }
)
