import { observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { i18nInstance } from 'app/utils/i18n'
import { X } from 'lucide-react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import enMessages from '../locales/en/upgradeDialog.json'
import zhMessages from '../locales/zh/upgradeDialog.json'
import { CardDecorator } from './components/CardDecorator/CardDecorator'

i18nInstance.addResourceBundle('en', 'upgradeDialog', enMessages)
i18nInstance.addResourceBundle('zh', 'upgradeDialog', zhMessages)

const open$ = observable(false)

export const showUpgradeDialog = () => open$.set(true)
export const hideUpgradeDialog = () => open$.set(false)

export const UpgradeDialog = observer(() => {
  const [t] = useTranslation('upgradeDialog')
  const router = useRouter()

  return (
    <Modal isOpen={open$.get()} onClose={() => {}} showCloseButton={false}>
      <div className="flex w-[100vw] max-w-[400px] flex-col items-center gap-3 p-4">
        <div className="aspect-square w-52">
          <img
            src="https://api.mydreamboy.com/storage/v1/object/public/static/upgrade_premium.png"
            alt="upgrade_premium"
            className="h-full w-full"
          />
        </div>
        <p className="font-bold">{t('title')}</p>
        <p className="text-center text-gray-800">{t('desc')}</p>
        <div className="flex justify-center gap-3">
          <Button
            mode="transparent"
            onClick={hideUpgradeDialog}
            showArrow={false}
            className="w-auto text-nowrap px-4 py-2 text-base"
          >
            {t('cancel')}
          </Button>
          <Button
            mode="white"
            showArrow={false}
            className="w-auto text-nowrap px-3 py-2 text-base font-bold text-black"
            onClick={() => {
              router.push('/billings')
              hideUpgradeDialog()
            }}
          >
            {t('confirm')}
          </Button>
        </div>
        <ActionIcon
          icon={<X size={14} />}
          onClick={hideUpgradeDialog}
          className="absolute right-2 top-2"
          square
        />
        <CardDecorator />
      </div>
    </Modal>
  )
})
