import { observer } from '@legendapp/state/react'
import { Dropdown } from 'app/components/Dropdown'
import { UserAvatar } from 'app/features/users/UserAvatar/UserAvatar'

import { MenuOptions } from './components/MenuOptions'

export const UserMenu = observer(() => {
  return (
    <Dropdown
      trigger={
        <div className="flex items-center rounded-full border-2 border-gray-200">
          <UserAvatar useSelfInfo className="h-9 w-9 md:h-12 md:w-12" />
        </div>
      }
      placement="bottom-end"
      placementClassName="fixed left-0 right-0 md:absolute md:left-auto md:right-0 bg-[#0C0C0C] md:border-[1px] md:border-white/10 md:w-[308px]"
      enableHover={false}
      backdropClassName="bg-black/10"
    >
      <MenuOptions />
    </Dropdown>
  )
})
