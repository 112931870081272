import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import { i18nInstance } from 'app/utils/i18n'
import { Plus } from 'lucide-react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

import enMessages from '../../locales/en/modelCreate.json'
import zhMessages from '../../locales/zh/modelCreate.json'
i18nInstance.addResourceBundle('en', 'modelCreateButton', enMessages)
i18nInstance.addResourceBundle('zh', 'modelCreateButton', zhMessages)

export const ModelCreateButton = observer(() => {
  const [t] = useTranslation('modelCreateButton')
  const router = useRouter()

  return (
    <Button
      mode="transparent"
      showArrow={false}
      className="font-grotesque w-auto items-center rounded-lg bg-[#FFC24C] px-4 pb-2 pt-1 font-bold text-black transition-all duration-200 hover:scale-105 hover:bg-[#FFC24C]/90 active:scale-95 active:bg-[#FFC24C]/95"
      onClick={() => router.push('/model/generator')}
    >
      <Plus className="mt-0.5 h-4 w-4" strokeWidth={2.5} />
      {t('create')}
    </Button>
  )
})
