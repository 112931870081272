import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.66667 18.3333C14.4531 18.3333 18.3333 14.4531 18.3333 9.66667C18.3333 4.8802 14.4531 1 9.66667 1C4.8802 1 1 4.8802 1 9.66667C1 14.4531 4.8802 18.3333 9.66667 18.3333ZM9.39984 6.46667C10.5531 6.46667 11.3332 7.32948 11.3332 8.23333C11.3332 8.88824 10.9732 9.22283 10.3524 9.59992C10.2831 9.64201 10.2032 9.68843 10.1178 9.73806C9.87349 9.87991 9.58363 10.0483 9.36578 10.218C9.04265 10.4698 8.6665 10.876 8.6665 11.5C8.6665 11.905 8.99483 12.2333 9.39984 12.2333C9.80299 12.2333 10.1302 11.908 10.1331 11.5056L10.1339 11.5044C10.1454 11.4861 10.1797 11.4431 10.2672 11.3749C10.4025 11.2695 10.5532 11.1822 10.754 11.0658C10.8587 11.0051 10.9771 10.9365 11.1139 10.8534C11.8265 10.4205 12.7998 9.70509 12.7998 8.23333C12.7998 6.47052 11.3133 5 9.39984 5C7.4864 5 5.99984 6.47052 5.99984 8.23333C5.99984 8.63834 6.32816 8.96667 6.73317 8.96667C7.13818 8.96667 7.4665 8.63834 7.4665 8.23333C7.4665 7.32948 8.2466 6.46667 9.39984 6.46667ZM9.39969 14.9C8.84741 14.9 8.39969 14.4523 8.39969 13.9C8.39969 13.3477 8.84741 12.9 9.39969 12.9C9.95198 12.9 10.3997 13.3477 10.3997 13.9C10.3997 14.4523 9.95198 14.9 9.39969 14.9Z"
      fill="currentColor"
    />
  </svg>
)
export const IconHelp = memo(SvgComponent)
