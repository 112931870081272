import { cn } from '@my/magic-ui/src'
import { useMemoizedFn } from 'ahooks'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState, useEffect } from 'react'

type Placement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'bottom-left-start'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end'

interface DropdownProps {
  trigger: React.ReactNode
  children: React.ReactNode
  placement?: Placement
  defaultOpen?: boolean
  zIndex?: number
  enableHover?: boolean
  onOpenChange?: (open: boolean) => void
  placementClassName?: string
  backdropClassName?: string
}

const getPlacementStyles = (placement: Placement): string => {
  const positions: Record<Placement, string> = {
    top: 'bottom-[92%] left-1/2 -translate-x-1/2',
    'top-start': 'bottom-[92%] left-0',
    'top-end': 'bottom-[92%] right-0',
    bottom: 'top-[92%] left-1/2 -translate-x-1/2',
    'bottom-start': 'top-[92%] left-0',
    'bottom-end': 'top-[92%] right-0',
    'bottom-left-start': 'top-[92%] right-0',
    left: 'right-[92%] top-1/2 -translate-y-1/2',
    'left-start': 'right-[92%] top-0',
    'left-end': 'right-[92%] bottom-0',
    right: 'left-[92%] top-1/2 -translate-y-1/2',
    'right-start': 'left-[92%] top-0',
    'right-end': 'left-[92%] bottom-0',
  }

  return positions[placement]
}

const getAnimationVariants = (placement: Placement) => {
  const isVertical = placement.startsWith('top') || placement.startsWith('bottom')
  const isHorizontal = placement.startsWith('left') || placement.startsWith('right')

  if (isVertical) {
    const y = placement.startsWith('top') ? 10 : -10
    return {
      initial: { opacity: 0, y },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y },
    }
  }

  if (isHorizontal) {
    const x = placement.startsWith('left') ? 10 : -10
    return {
      initial: { opacity: 0, x },
      animate: { opacity: 1, x: 0 },
      exit: { opacity: 0, x },
    }
  }
}

export const Dropdown = ({
  trigger,
  children,
  placement = 'bottom',
  defaultOpen = false,
  zIndex,
  enableHover = true,
  onOpenChange,
  placementClassName,
  backdropClassName,
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const placementStyles = getPlacementStyles(placement)
  const animationVariants = getAnimationVariants(placement)

  const handleOpenChange = useMemoizedFn((newState: boolean) => {
    setIsOpen(newState)
    onOpenChange?.(newState)
  })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        handleOpenChange(false)
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleOpenChange, isOpen])

  return (
    <>
      <motion.div
        ref={dropdownRef}
        className={`relative z-${zIndex ?? 50} inline-block w-full`}
        whileHover={{ cursor: 'pointer' }}
        onHoverStart={() => enableHover && handleOpenChange(true)}
        onHoverEnd={() => enableHover && handleOpenChange(false)}
        onClick={(e) => {
          e.stopPropagation()
          handleOpenChange(!isOpen)
        }}
      >
        <div className="w-full cursor-pointer">{trigger}</div>

        <AnimatePresence>
          {isOpen && (
            <>
              <motion.div
                {...animationVariants}
                transition={{ duration: 0.2 }}
                className={cn(
                  'absolute z-50 rounded-md shadow-lg ring-1 ring-black ring-opacity-5',
                  placementStyles,
                  placementClassName
                )}
              >
                <div className="py-1">{children}</div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </motion.div>
      {isOpen && (
        <div
          className={cn(
            'fixed inset-0 bottom-0 left-0 right-0 top-0 z-10 h-screen w-screen',
            backdropClassName
          )}
          onClick={(e) => {
            e.stopPropagation()
            handleOpenChange(false)
          }}
        />
      )}
    </>
  )
}
