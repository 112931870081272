import { useObservable } from '@legendapp/state/react'
import { useUser } from 'app/utils/useUser'
import constate from 'constate'
import { useRef } from 'react'

const useCommentsInner = ({ topic }: { topic: string }) => {
  const enabled = !!useUser().user
  const editorInput = useRef<HTMLInputElement>(null)
  const editor$ = useObservable({
    value: '',
    placeholder: 'Write a message...',
    readOnly: false,
    focus: false,
  })
  return { topic, enabled, editor$, editorInput }
}

export const [CommentsProvider, useCommentsContext] = constate(useCommentsInner)
