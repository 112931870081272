import { useLanguage } from 'app/features/settings/hooks/useLanguage'
import { IconYellowCrown } from 'app/icons/IconYellowCrown'
import { useSupabase } from 'app/utils/supabase/useSupabase'
import { useUser } from 'app/utils/useUser'
import { Languages } from 'lucide-react'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { IconAffiliate } from '../icons/IconAffiliate'
import { IconContact } from '../icons/IconContact'
import { IconCreation } from '../icons/IconCreation'
import { IconHelp } from '../icons/IconHelp'
import { IconLogout } from '../icons/IconLogout'
import { IconProfile } from '../icons/IconProfile'

export const useOptions = () => {
  const router = useRouter()
  const supabase = useSupabase()
  const { switchLanguage } = useLanguage()

  const { isLogin } = useUser()

  const options = useMemo(() => {
    return [
      [
        {
          icon: IconProfile,
          label: 'Profile',
          onClick: () => {
            router.push('/settings')
          },
        },
        {
          icon: IconCreation,
          label: 'My creations',
          onClick: () => {
            router.push('/profile')
          },
        },
        {
          icon: IconYellowCrown,
          label: 'Upgrade',
          onClick: () => {
            router.push('/billings')
          },
        },
      ],
      [
        {
          icon: IconContact,
          label: 'Contact us',
          onClick: () => {
            location.href = 'mailto:support@mydreamboy.com'
          },
        },
        {
          icon: IconHelp,
          label: 'Help docs',
          onClick: () => {
            location.href = 'https://docs.mydreamboy.com'
          },
        },
        {
          icon: IconAffiliate,
          label: 'Affiliate program',
          onClick: () => {
            router.push('/affiliate')
          },
        },
      ],
      isLogin
        ? [
            {
              icon: Languages,
              label: 'Switch language',
              onClick: switchLanguage,
            },
            {
              icon: IconLogout,
              label: 'Logout',
              onClick: () => {
                supabase.auth.signOut()
              },
            },
          ]
        : [
            {
              icon: Languages,
              label: 'Switch language',
              onClick: switchLanguage,
            },
          ],
    ]
  }, [router, supabase.auth, switchLanguage, isLogin])

  return options
}
