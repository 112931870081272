import { cn } from '@my/magic-ui/src'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import Link from 'next/link'

import { getDefaultTagIdsMap } from '../utils/jsonToMap'

interface Props {
  combo: number[]
  isLink?: boolean
  labelClassName?: string
}

const tagsMap = getDefaultTagIdsMap()

export const TagComboLabel = ({ combo, isLink = true, labelClassName }: Props) => {
  const { i18n } = useTranslation()
  return (
    <div className="flex flex-1">
      <ContextWrapper combo={combo} isLink={isLink}>
        <p className={cn('text-primary-active', labelClassName)}>
          #
          {combo
            .sort((a, b) => a - b)
            .map((id) =>
              i18n.resolvedLanguage === 'zh' ? tagsMap[id]?.cn_name : tagsMap[id]?.en_name
            )
            .join('_')}
        </p>
      </ContextWrapper>
    </div>
  )
}

function ContextWrapper({ children, isLink, combo }: { children: ReactNode } & Props) {
  if (isLink) {
    return (
      <Link href={`/explore/prompts/${encodeURIComponent(JSON.stringify([combo]))}`}>
        {children}
      </Link>
    )
  }

  return <>{children}</>
}
