import { i18nInstance } from 'app/utils/i18n'

import { CommentList } from './components/CommentList'
import { CommentsProvider } from './context/CommentsContext'
import enMessages from '../locales/en/commentList.json'
import zhMessages from '../locales/zh/commentList.json'

i18nInstance.addResourceBundle('en', 'commentList', enMessages)
i18nInstance.addResourceBundle('zh', 'commentList', zhMessages)

export const Comments = ({ topic }: { topic: string }) => {
  return (
    <CommentsProvider topic={topic}>
      <p className="my-4 text-sm text-gray-700">Comments</p>
      <CommentList parentId={null} />
    </CommentsProvider>
  )
}
