'use client'

import { cn } from '@my/magic-ui/src'
import { Eye, EyeOff } from 'lucide-react'
import { forwardRef, useState } from 'react'
import type { FieldError } from 'react-hook-form'

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string
  name: string
  type: string
  placeholder: string
  error?: FieldError
}

export const FormField = forwardRef<HTMLInputElement, FormFieldProps>(
  ({ label, type, error, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    return (
      <div className="space-y-2">
        {label && (
          <label className="font-grotesque block text-lg font-bold leading-5 text-white">
            {label}
          </label>
        )}
        <div className="relative">
          <div className="relative">
            <input
              {...props}
              ref={ref}
              type={type === 'password' && showPassword ? 'text' : type}
              className={cn(
                'font-grotesque w-full rounded-[10px] border border-solid border-white/20 bg-[#35353533]/20 px-4 py-3.5 text-base font-bold text-white placeholder:text-white/30',
                error && 'border-red-500'
              )}
            />
            {type === 'password' && (
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 z-10 flex items-center pr-3 text-white/50"
              >
                {showPassword ? <Eye size={18} /> : <EyeOff size={18} />}
              </button>
            )}
          </div>
          {error && <p className="text-sm text-red-500 md:text-base">{error.message}</p>}
        </div>
      </div>
    )
  }
)

FormField.displayName = 'FormField'
