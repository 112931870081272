import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { useMemoizedFn } from 'ahooks'
import { Button } from 'app/components/Button'
import { CircleCheck } from 'lucide-react'
import { useForm } from 'react-hook-form'

import { FormField } from './components/FormField'
import { useResetPassword } from './hooks/useResetPassword'

interface ForgetPasswordFormProps {
  className?: string
  onSignIn: () => void
}

interface FormData {
  email: string
}

export const ForgetPasswordForm = observer(({ className, onSignIn }: ForgetPasswordFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: '',
    },
  })
  const { onResetPassword, loading$, success$ } = useResetPassword()

  const onSubmit = useMemoizedFn(async (data: FormData) => {
    onResetPassword({ email: data.email })
  })

  if (success$.get()) {
    return <CheckYourEmail />
  }

  return (
    <div className={cn('font-grotesque px-6 py-8', className)}>
      <p className="text-center text-3xl font-bold">Forgot password</p>
      <p className="mt-2 text-center text-lg font-bold text-white/60">
        Enter your email and we will send you a link to reset your password
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-4">
        <FormField
          label="Email"
          type="email"
          placeholder="Enter your email"
          error={errors.email}
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          })}
        />
      </form>
      <p className="mt-7 font-bold text-white">
        Done reseting?{' '}
        <span className="cursor-pointer text-[#FFC24C] underline" onClick={onSignIn}>
          Sign in
        </span>
      </p>
      <Button
        className="mt-4 w-full bg-[#FFC24C] pb-4 pt-3 text-xl font-bold text-black"
        showArrow={false}
        mode="transparent"
        disabled={loading$.get()}
        loading={loading$.get()}
        onClick={handleSubmit(onSubmit)}
      >
        Send link
      </Button>
    </div>
  )
})

const CheckYourEmail = () => {
  return (
    <div className="flex flex-col items-center justify-center px-6 py-8">
      <CircleCheck className="h-10 w-10 text-[#FFC24C]" />
      <p className="font-grotesque mt-4 text-center text-xl font-bold text-white">
        We have sent you a link to reset your password, please check your email.
      </p>
    </div>
  )
}
