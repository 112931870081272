import { observer, useComputed } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { ImageWithSkeleton } from 'app/components/ImageWithSkeleton'
import { useUser } from 'app/utils/useUser'
import { User } from 'lucide-react'

export const UserAvatar = observer(
  ({
    avatarUrl,
    useSelfInfo = false,
    className,
  }: {
    avatarUrl?: string
    useSelfInfo?: boolean
    className?: string
  }) => {
    const { avatarUrl$ } = useUser()
    const finalizeAvatarUrl$ = useComputed(() => {
      const url = useSelfInfo ? avatarUrl$.get() : avatarUrl
      return url
        ? url?.startsWith('http')
          ? url
          : `${process.env.NEXT_PUBLIC_SUPABASE_URL}/storage/v1/object/public/${url}`
        : null
    })

    if (!finalizeAvatarUrl$.get()) {
      return (
        <div className={cn('h-12 w-12 overflow-hidden rounded-full', className)}>
          <div className="flex h-full w-full items-center justify-center bg-gray-100">
            <User className="h-6 w-6" />
          </div>
        </div>
      )
    }

    return (
      <div className={cn('h-12 w-12 overflow-hidden rounded-full', className)}>
        <ImageWithSkeleton
          src={finalizeAvatarUrl$.get()!}
          className="h-full w-full flex-1"
          imgClassName="object-cover"
        />
      </div>
    )
  }
)
