import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Plus } from 'lucide-react'
import { useRouter } from 'next/router'

export const PlayTabItem = observer(({ open$ }: { open$: Observable<boolean> }) => {
  const router = useRouter()
  return (
    <div
      className="absolute -top-[18px] left-1/2 z-[9999] flex -translate-x-1/2 overflow-visible"
      onClick={() => router.push('/generate')}
    >
      <div
        className="bg-coal-400 relative cursor-pointer overflow-hidden rounded-full bg-gradient-to-br from-blue-300 via-green-200 to-blue-600 p-3"
        onClick={() => open$.set(!open$.peek())}
      >
        <div className="flex">
          <Plus strokeWidth={3} color="white" />
        </div>
      </div>
      <div className="absolute -right-2 -top-2">
        <div className="flex items-center justify-center rounded-full bg-red-400 px-2 py-1 text-[8px] font-bold text-white">
          NEW
        </div>
      </div>
    </div>
  )
})
