import { observer, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src/lib/utils'
import { useMemoizedFn } from 'ahooks'
import { Button } from 'app/components/Button'
import { copyToClipboard } from 'app/utils/hooks/useClipboard'
import { useUser } from 'app/utils/useUser'
import { Check, Lock, Copy } from 'lucide-react'
import { useState, useRef, useEffect } from 'react'

import { store$ } from '../context/PostDetailModelContext'

export const PostCustomizePrompt = observer(() => {
  const prompt = store$.post.get().customizePrompt || store$.post.get().customize_prompt || ''
  const version = store$.model.get().version
  const creatorId = store$.post.get().creator ?? store$.post.get().creatorVO?.profiles.id

  const { user } = useUser()
  const isSelf = user?.id === creatorId

  if (version !== 'v2' || !prompt) return null

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <p className="text-sm text-gray-700">Prompt</p>
        {isSelf ? <CopyButton prompt={prompt} /> : <LockedTag />}
      </div>
      {isSelf ? <CustomizePrompt prompt={prompt} /> : <LockedPrompt />}
    </div>
  )
})

const LockedTag = () => {
  return (
    <div className="flex items-center gap-1 rounded-full bg-gray-100 px-2 py-1 text-xs text-gray-700">
      <Lock className="h-3 w-3" />
      Locked
    </div>
  )
}

const CopyButton = observer(({ prompt }: { prompt: string }) => {
  const copied$ = useObservable(false)

  const handleCopy = useMemoizedFn(() => {
    copied$.set(true)
    copyToClipboard(prompt)
    setTimeout(() => {
      copied$.set(false)
    }, 3000)
  })

  return (
    <Button
      mode="transparent"
      showArrow={false}
      className={cn(
        'flex w-auto items-center gap-2 px-2 py-1 text-xs text-gray-700 transition-all duration-200',
        copied$.get() ? 'bg-green-500/20 text-green-400' : 'hover:bg-gray-100 hover:text-gray-800'
      )}
      onClick={handleCopy}
    >
      {copied$.get() ? (
        <>
          <Check className="h-3 w-3" />
          <span className="">Copied!</span>
        </>
      ) : (
        <>
          <Copy className="h-3 w-3" />
          <span className="">Copy</span>
        </>
      )}
    </Button>
  )
})

const LockedPrompt = () => {
  return (
    <div className="relative min-h-16 gap-2 text-sm text-gray-700 md:min-h-24">
      <div className="flex flex-col gap-1 md:gap-3">
        <p>You can not see other creator's customize prompt for now</p>
        <p>We are working to support this feature</p>
      </div>
      <div className="absolute inset-0 z-10 flex flex-col items-center justify-center gap-1 rounded-lg bg-black/10 backdrop-blur-sm">
        <Lock className="h-4 w-4" />
        <p>You can not see other creator's prompt for now</p>
      </div>
    </div>
  )
}

const maxLines = 2 // 最大行数

const CustomizePrompt = observer(({ prompt }: { prompt: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [needsTruncation, setNeedsTruncation] = useState(false)
  const [truncatedText, setTruncatedText] = useState('')
  const textRef = useRef<HTMLSpanElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const truncateText = useMemoizedFn((container, textElement, text, maxHeight) => {
    let truncated = text
    textElement.textContent = truncated

    // 逐步截断，直到加上“...”和“展示更多”后仍符合高度
    while (container.scrollHeight > maxHeight && truncated.length > 0) {
      truncated = truncated.slice(0, -2)
      textElement.textContent = truncated
      // 模拟添加“...”和“展示更多”后的高度检查
      const tempContent = `${truncated}... show more `
      textElement.textContent = tempContent
      if (container.scrollHeight > maxHeight) {
        textElement.textContent = truncated // 恢复截断状态，继续循环
      } else {
        break
      }
    }
    setTruncatedText(truncated)
  })

  const handleToggle = useMemoizedFn(() => {
    setIsExpanded(!isExpanded)
  })

  useEffect(() => {
    const checkAndTruncate = () => {
      const container = containerRef.current
      const textElement = textRef.current
      if (!container || !textElement) return

      const lineHeight = parseFloat(window.getComputedStyle(textElement).lineHeight)
      const maxHeight = lineHeight * maxLines

      // 检查完整文本是否需要截断
      textElement.textContent = prompt
      if (container.scrollHeight > maxHeight) {
        setNeedsTruncation(true)
        truncateText(container, textElement, prompt, maxHeight)
      } else {
        setNeedsTruncation(false)
        setTruncatedText(prompt)
      }
    }

    checkAndTruncate()
  }, [prompt, truncateText])

  return (
    <div className="text-sm leading-6 text-gray-700" ref={containerRef}>
      <span ref={textRef} className="whitespace-pre-line break-all">
        {isExpanded ? prompt : truncatedText}
      </span>
      {needsTruncation && (
        <>
          {!isExpanded && <span className="ellipsis">...</span>}
          <span
            className="ml-1 cursor-pointer whitespace-nowrap text-gray-800 underline"
            onClick={handleToggle}
          >
            {isExpanded ? 'show less' : 'show more'}
          </span>
        </>
      )}
    </div>
  )
})
