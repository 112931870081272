import { observer, useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { ActionIcon } from 'app/components/ActionIcon'
import { Drawer } from 'app/components/Drawer'
import { DiscordLink } from 'app/features/socialMedia/DiscordLink/DiscordLink'
import { IconLeft } from 'app/icons/IconLeft'
import { IconMenu } from 'app/icons/IconMenu'
import { IconShortName } from 'app/icons/IconShortName'
import { useHistory } from 'app/provider/history-manager/HistoryManagerProvider'
import { getExplorePostLink } from 'app/utils/model-version'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { SearchButton } from './SearchButton'
import { SidebarMenu } from './SidebarMenu'
import { UpgradeMenu } from './UpgradeMenu'
import { UserMenu } from './UserMenu'
import { useIsMobileTabShown } from '../../hooks/homeLayout'
import { MOBILE_HEADER_HEIGHT, SIDEBAR_WIDTH } from '../constants/layoutToken'

export const MobileHeader = observer(() => {
  const isMobileTabShown = useIsMobileTabShown()
  const isMenuOpen$ = useObservable(false)
  const { canGoBack } = useHistory()
  const router = useRouter()
  const onBack = useMemoizedFn(() => {
    if (canGoBack) {
      router.back()
    } else {
      router.replace(getExplorePostLink())
    }
  })

  return (
    <>
      <div
        className="bg-coal-100/80 fixed left-0 right-0 top-0 z-50 flex w-full items-center justify-between px-4 backdrop-blur-[12px] [--animation-delay:600ms]"
        style={{ height: MOBILE_HEADER_HEIGHT }}
      >
        <div className="flex items-center gap-2">
          {isMobileTabShown ? (
            <ActionIcon
              icon={<IconMenu className="h-4 w-4" />}
              className="active:text-gray-800"
              onClick={() => isMenuOpen$.set(true)}
            />
          ) : (
            <ActionIcon
              icon={<IconLeft className="h-4 w-4" />}
              className="active:text-gray-800"
              rounded
              onClick={onBack}
            />
          )}
          <Link href="/">
            <IconShortName className="w-5" />
          </Link>
        </div>
        <div className="flex items-center gap-2">
          <DiscordLink />
          <UpgradeMenu />
          <SearchButton />
          <UserMenu />
        </div>
      </div>
      <Drawer isOpen={isMenuOpen$.get()} onClose={() => isMenuOpen$.set(false)} direction="left">
        <div style={{ width: SIDEBAR_WIDTH, height: '100%' }}>
          <SidebarMenu
            isCompact={false}
            showLogo={false}
            onItemClick={() => isMenuOpen$.set(false)}
          />
        </div>
      </Drawer>
    </>
  )
})
