import { observer, useComputed, useObservable } from '@legendapp/state/react'
import { ActionIcon } from 'app/components/ActionIcon'
import { Dropdown } from 'app/components/Dropdown'
import { useIsPrivatePost } from 'app/features/posts/hooks/usePostStatus'
import { IconDotsHorizontal } from 'app/icons/IconDotsHorizontal'
import { copyToClipboard } from 'app/utils/hooks/useClipboard'
import { useUser } from 'app/utils/useUser'
import { ArrowDownToLine, Link as IconLink, Lock, Redo, Trash2, Unlock } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { store$ } from '../../context/PostDetailModelContext'
import { onDownload } from '../../event'
import { ChangeScopeDialog } from './components/ChangeScopeDialog'
import { DeletePostDialog } from './components/DeletePostDialog'
import { showShareDialog } from './components/ShareDialog'

export const PostDetailMenu = observer(() => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'menu' })
  const { user } = useUser()
  const deleteDialog$ = useObservable(false)
  const scopeDialog$ = useObservable(false)
  const isSelfPost = store$.post.creator.get() === user?.id
  const isPrivatePost = useIsPrivatePost(store$.post.get())
  const isRemainFace$ = useComputed(() => {
    const images = store$.post.images.get()
    return images?.some(
      (image) => image.inpaint_info && !!image.inpaint_info.remain_face_human_ids.length
    )
  })

  return (
    <>
      <Dropdown
        zIndex={50}
        enableHover={false}
        placement="bottom-end"
        trigger={
          <div className="flex justify-end">
            <ActionIcon
              icon={<IconDotsHorizontal className="h-4 w-4 stroke-2" />}
              square
              className="w-auto flex-grow-0 bg-white/5"
            />
          </div>
        }
      >
        <div className="flex min-w-[180px] flex-col items-center rounded-xl bg-gray-100 p-2 shadow-xl shadow-white/10">
          {!isPrivatePost && <MenuItem text={t('share')} icon={Redo} onPress={showShareDialog} />}
          <MenuItem
            icon={ArrowDownToLine}
            text={t('download')}
            onPress={() => {
              onDownload.fire()
            }}
          />
          <MenuItem
            text={t('copy')}
            icon={IconLink}
            onPress={() => copyToClipboard(`${location.origin}/post/${store$.post.id.peek()}`)}
          />
          {isSelfPost && !isRemainFace$.get() && (
            <MenuItem
              text={isPrivatePost ? t('public') : t('private')}
              icon={isPrivatePost ? Unlock : Lock}
              onPress={() => scopeDialog$.set(true)}
            />
          )}
          {isSelfPost && (
            <MenuItem
              text={t('delete')}
              icon={Trash2}
              iconClassName="text-danger-active"
              textClassName="text-danger"
              onPress={() => deleteDialog$.set(true)}
            />
          )}
        </div>
      </Dropdown>
      <DeletePostDialog open$={deleteDialog$} />
      <ChangeScopeDialog open$={scopeDialog$} />
    </>
  )
})

const MenuItem = ({
  text,
  onPress,
  className,
  iconClassName,
  textClassName,
  icon: Icon,
}: {
  text: string
  onPress: () => void
  className?: string
  iconClassName?: string
  textClassName?: string
  icon: React.ElementType
}) => {
  return (
    <div
      className={`flex w-full cursor-pointer items-center gap-2 rounded-lg p-2 hover:bg-gray-200 ${className}`}
      onClick={onPress}
    >
      <Icon strokeWidth={3} size={16} className={iconClassName} />
      <p className={`cursor-pointer text-sm font-bold ${textClassName}`}>{text}</p>
    </div>
  )
}
