import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { deletedPostIds$ } from 'app/features/posts/stores/operatedPosts'
import { api } from 'app/utils/api'
import { AlertCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { hideModal, store$ } from '../../../context/PostDetailModelContext'

export const DeletePostDialog = observer(({ open$ }: { open$: Observable<boolean> }) => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'deleteDialog' })
  const deleteMutation = api.post.delete.useMutation()

  const onDelete = async () => {
    const postId = Number(store$.post.id.peek())
    deleteMutation.mutateAsync({ id: postId }).catch(() => deletedPostIds$.delete(postId))
    deletedPostIds$.add(postId)
    open$.set(false)
    hideModal()
  }

  return (
    <Modal
      isOpen={open$.get()}
      onClose={() => open$.set(false)}
      showCloseButton={false}
      mountToBody={false}
    >
      <div className="relative flex w-[90vw] max-w-[400px] flex-col items-center gap-2 p-4 text-center">
        <AlertCircle className="text-danger-active h-8 w-8" />
        <p className="text-lg font-bold">{t('title')}</p>
        <p className="text-sm text-gray-800">{t('desc')}</p>
        <div className="mt-4 flex gap-2">
          <Button
            mode="transparent"
            showArrow={false}
            onClick={onDelete}
            className="bg-danger w-auto px-4 py-2 text-sm font-bold text-white md:text-base"
          >
            {t('confirm')}
          </Button>
          <Button
            mode="transparent"
            showArrow={false}
            onClick={() => open$.set(false)}
            className="w-auto px-4 py-2 text-sm font-bold md:text-base"
          >
            {t('cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  )
})
