import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.38881 18.3337H13.611C16.4362 18.3337 18.3337 16.3519 18.3337 13.4031V6.59759C18.3337 3.64875 16.4362 1.66699 13.6118 1.66699H6.38881C3.56441 1.66699 1.66699 3.64875 1.66699 6.59759V13.4031C1.66699 16.3519 3.56441 18.3337 6.38881 18.3337ZM7.08275 9.16703C5.93388 9.16703 5.00037 8.23226 5.00037 7.0837C5.00037 5.93514 5.93388 5.00037 7.08275 5.00037C8.23078 5.00037 9.16513 5.93514 9.16513 7.0837C9.16513 8.23226 8.23078 9.16703 7.08275 9.16703ZM16.5177 12.4454C16.7968 13.1609 16.6518 14.0209 16.3535 14.7295C15.9999 15.5723 15.3228 16.2105 14.4697 16.4891C14.0909 16.6129 13.6937 16.6671 13.2974 16.6671H6.27426C5.57538 16.6671 4.95695 16.4994 4.44997 16.1872C4.13238 15.9912 4.07623 15.5388 4.31171 15.2455C4.70556 14.7553 5.09438 14.2634 5.48656 13.7672C6.23403 12.8178 6.73766 12.5426 7.29743 12.7842C7.52452 12.884 7.75245 13.0336 7.98709 13.1919C8.61222 13.6167 9.48121 14.2006 10.6259 13.5668C11.4092 13.1281 11.8636 12.3756 12.2592 11.7202L12.2658 11.7092C12.2938 11.6634 12.3215 11.6175 12.3492 11.5718L12.3493 11.5714C12.4823 11.3514 12.6135 11.1344 12.7619 10.9344C12.9479 10.6841 13.6376 9.90154 14.5309 10.4588C15.0999 10.8097 15.5784 11.2844 16.0904 11.7927C16.2856 11.987 16.4247 12.208 16.5177 12.4454Z"
      fill="currentColor"
    />
  </svg>
)
export const IconCreation = memo(SvgComponent)
