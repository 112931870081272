import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src/lib/utils'
import { ImageWithSkeleton } from 'app/components/ImageWithSkeleton'

import { localModelAvatar$ } from './store'

type Props = {
  pid: string
  modelId: string
  className?: string
}

export const ModelAvatar = observer(({ pid, modelId, className }: Props) => {
  const normalizePid = localModelAvatar$.get()[modelId] ?? pid
  return (
    <div className={cn('h-12 w-12 overflow-hidden rounded-full', className)}>
      <ImageWithSkeleton
        src={`${process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX}${normalizePid}`}
        className="flex-1"
      />
    </div>
  )
})
