import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
  // 生成唯一前缀
  const prefix = `svg_${Math.random().toString(36).substr(2, 9)}`

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${prefix}_clip0_2262_8480)`}>
        <g filter={`url(#${prefix}_filter0_d_2262_8480)`}>
          <path
            d="M35.2082 12.0764C34.9602 11.9284 34.6922 11.8564 34.4202 11.8564C33.8642 11.8564 33.3362 12.1404 33.0402 12.6564C32.6082 13.4084 32.8682 14.3804 33.6202 14.8124C33.9882 15.0324 34.4202 15.0844 34.8282 14.9724C35.2362 14.8604 35.5802 14.6044 35.7882 14.2324C35.9962 13.8644 36.0602 13.4444 35.9482 13.0364C35.8362 12.6164 35.5762 12.2844 35.2082 12.0764Z"
            fill={`url(#${prefix}_paint0_linear)`}
          />
          <path
            d="M24.9522 19.5085L29.3642 16.2925L27.9082 12.9885C27.7482 13.0365 27.5762 13.0605 27.4162 13.0605C27.2442 13.0605 27.0722 13.0365 26.9242 12.9885L24.4962 18.4725L24.9522 19.5085Z"
            fill={`url(#${prefix}_paint1_linear)`}
          />
          <path
            d="M28.4282 11.1244C28.4282 10.5564 27.9722 10.1004 27.4162 10.1004C26.8482 10.1004 26.3922 10.5564 26.3922 11.1244C26.3922 11.6804 26.8482 12.1364 27.4162 12.1364C27.9722 12.1364 28.4282 11.6804 28.4282 11.1244Z"
            fill={`url(#${prefix}_paint2_linear_2262_8480)`}
          />
          <path
            d="M21.5842 9.99239C21.5842 9.11639 20.8682 8.40039 19.9922 8.40039C19.1162 8.40039 18.4162 9.11639 18.4162 9.99239C18.4162 10.8684 19.1202 11.5684 19.9922 11.5684C20.8722 11.5684 21.5842 10.8684 21.5842 9.99239Z"
            fill={`url(#${prefix}_paint3_linear_2262_8480)`}
          />
          <path
            d="M13.5962 11.1244C13.5962 10.5564 13.1402 10.1004 12.5722 10.1004C12.0162 10.1004 11.5602 10.5564 11.5602 11.1244C11.5602 11.6804 12.0162 12.1364 12.5722 12.1364C13.1402 12.1364 13.5962 11.6804 13.5962 11.1244Z"
            fill={`url(#${prefix}_paint4_linear_2262_8480)`}
          />
          <path
            d="M15.0522 19.5085L15.4962 18.4845L13.0682 12.9885C12.9202 13.0365 12.7482 13.0605 12.5762 13.0605C12.4162 13.0605 12.2442 13.0365 12.0842 12.9885L10.6282 16.2925L15.0522 19.5085Z"
            fill={`url(#${prefix}_paint5_linear_2262_8480)`}
          />
          <path
            d="M6.37617 14.8125C6.62417 14.6645 6.82017 14.4805 6.95617 14.2325C7.24017 13.7525 7.24017 13.1365 6.95617 12.6405C6.67217 12.1365 6.13217 11.8525 5.58817 11.8525C5.31617 11.8525 5.04417 11.9245 4.80017 12.0725C4.03617 12.5045 3.77617 13.4765 4.22017 14.2405C4.63617 14.9725 5.62417 15.2565 6.37617 14.8125Z"
            fill={`url(#${prefix}_paint6_linear_2262_8480)`}
          />
          <path
            d="M6.84417 15.6126C6.62417 15.7486 6.37617 15.8206 6.12817 15.8846L8.69217 28.0526C16.1882 29.6806 23.7922 29.6806 31.3002 28.0526L33.8642 15.8846C33.6162 15.8246 33.3842 15.7486 33.1602 15.6126C32.9122 15.4766 32.7162 15.3046 32.5442 15.1086L25.0362 20.5926C25.0242 20.6046 25.0122 20.6046 25.0002 20.6046C24.9882 20.6166 24.9642 20.6406 24.9522 20.6406C24.8922 20.6766 24.8282 20.6886 24.7682 20.6886C24.7562 20.6886 24.7562 20.6886 24.7562 20.6886C24.6842 20.6886 24.6082 20.6646 24.5362 20.6166C24.5122 20.6046 24.5002 20.5926 24.4762 20.5686C24.4522 20.5446 24.4162 20.5326 24.3882 20.4966C24.3762 20.4846 24.3762 20.4606 24.3642 20.4366C24.3642 20.4366 24.3522 20.4246 24.3402 20.4126L23.5882 18.7006C23.5762 18.6766 23.5642 18.6526 23.5522 18.6286L20.7922 12.3686C20.5442 12.4566 20.2882 12.5046 20.0042 12.5046C19.7322 12.5046 19.4722 12.4566 19.2282 12.3686L16.4562 18.6566C16.4442 18.6686 16.4442 18.6686 16.4442 18.6686L15.6682 20.4206C15.6682 20.4326 15.6562 20.4326 15.6562 20.4446C15.6442 20.4686 15.6322 20.4926 15.6202 20.5046C15.5962 20.5406 15.5722 20.5526 15.5322 20.5766C15.5202 20.6006 15.4962 20.6126 15.4722 20.6246C15.4122 20.6726 15.3362 20.6966 15.2522 20.6966C15.1922 20.6966 15.1282 20.6846 15.0682 20.6486C15.0442 20.6486 15.0322 20.6246 15.0082 20.6126C15.0082 20.6126 14.9962 20.6126 14.9842 20.6006L7.46417 15.1166C7.27217 15.3046 7.07617 15.4766 6.84417 15.6126Z"
            fill={`url(#${prefix}_paint7_linear_2262_8480)`}
          />
          <path
            d="M31.1002 29.0365C27.4042 29.8005 23.6922 30.1965 19.9802 30.1965C16.2682 30.1965 12.5722 29.8005 8.88817 29.0365L9.30817 31.0445C16.3962 32.5365 23.5842 32.5245 30.6842 31.0445L31.1002 29.0365Z"
            fill={`url(#${prefix}_paint8_linear_2262_8480)`}
          />
        </g>
      </g>
      <defs>
        <filter
          id={`${prefix}_filter0_d_2262_8480`}
          x="0.00341797"
          y="6.40039"
          width="39.9993"
          height="31.7578"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0626227 0 0 0 0 0.0103819 0 0 0 0 0.108333 0 0 0 0.25 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2262_8480" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2262_8480"
            result="shape"
          />
        </filter>
        <linearGradient
          id={`${prefix}_paint0_linear`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint1_linear`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint2_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint3_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint4_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint5_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint6_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint7_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <linearGradient
          id={`${prefix}_paint8_linear_2262_8480`}
          x1="16.5716"
          y1="8.57171"
          x2="22.8574"
          y2="33.7146"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2D33A" />
          <stop offset="1" stopColor="#DD873A" />
        </linearGradient>
        <clipPath id={`${prefix}_clip0_2262_8480`}>
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export const IconYellowCrown = memo(SvgComponent)
