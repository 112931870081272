import { observer, useObservable } from '@legendapp/state/react'
import { Spinner } from 'app/components/Spinner'
import { IconChat } from 'app/icons/IconChat'
import { api } from 'app/utils/api'
import { i18nInstance } from 'app/utils/i18n'
import { useUser } from 'app/utils/useUser'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { toast } from 'sonner'

import enMessages from '../locales/en/startChatButton.json'
import zhMessages from '../locales/zh/startChatButton.json'

i18nInstance.addResourceBundle('en', 'startChatButton', enMessages)
i18nInstance.addResourceBundle('zh', 'startChatButton', zhMessages)

export const StartChatButton = observer(({ modelId }: { modelId: string }) => {
  const [t] = useTranslation('startChatButton')
  const getSessionMutation = api.chat.getSession.useMutation()
  const router = useRouter()
  const loading$ = useObservable(false)

  const { isLogin } = useUser()

  const onGetSession = async () => {
    if (!isLogin) {
      router.push('/sign-in')
      return
    }
    loading$.set(true)
    getSessionMutation.mutateAsync({ modelId }).then(
      (sessionId) => {
        router.push(`/chat?session=${sessionId}`)
      },
      () => {
        toast.error(t('failed'))
        loading$.set(false)
      }
    )
  }

  return (
    <button
      className="bg-coal-100/60 hover:text-primary flex flex-1 items-center gap-2 rounded-lg px-3 py-2 text-gray-600"
      onClick={onGetSession}
    >
      {loading$.get() ? <Spinner /> : <IconChat className="h-6 w-6" />}
      <p className="text-sm">{t('start')}</p>
    </button>
  )
})
