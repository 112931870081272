import { useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { useErrorToast } from 'app/hooks/useToast'
import { useSupabase } from 'app/utils/supabase/useSupabase'
import { UseFormSetError } from 'react-hook-form'

import { useTermsAndNewsletterCheck } from './useTermsAndNewsletterCheck'

export const useSignIn = () => {
  const loading$ = useObservable(false)

  const supabase = useSupabase()
  const { termsChecked$, shakeTerms } = useTermsAndNewsletterCheck()
  const { toastError } = useErrorToast()

  const onSignIn = useMemoizedFn(
    async ({
      email,
      password,
      setError,
      afterSignIn,
    }: {
      email: string
      password: string
      setError: UseFormSetError<{ email: string; password: string }>
      afterSignIn: () => void
    }) => {
      if (!termsChecked$.get()) {
        shakeTerms()
        return
      }
      loading$.set(true)
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      })
      if (error) {
        const errorMessage = error?.message.toLowerCase()
        if (errorMessage.includes('email')) {
          setError('email', { type: 'custom', message: errorMessage })
        } else if (errorMessage.includes('password')) {
          setError('password', { type: 'custom', message: errorMessage })
        } else {
          setError('email', { type: 'custom', message: errorMessage })
          setError('password', { type: 'custom', message: errorMessage })
        }
        toastError(error.message ?? 'Something went wrong')
      } else {
        afterSignIn?.()
      }
      loading$.set(false)
    }
  )

  return { onSignIn, loading$ }
}
