import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { InviteDialog } from 'app/features/ecommerce/InviteDialog/InviteDialog'
import { UpgradeDialog } from 'app/features/ecommerce/UpgradeDialog/UpgradeDialog'
import { PostDetailModal } from 'app/features/posts/PostDetail/PostDetailModal'
import { useIsMobile } from 'app/hooks/useMediaQuery'
import { i18nInstance } from 'app/utils/i18n'
import dynamic from 'next/dynamic'

import enMessages from '../locales/en/home.json'
import zhMessages from '../locales/zh/home.json'
import { homeLayout$ } from '../stores/homeLayout'
import { AuthModal } from './components/AuthModal/AuthModal'
import { ContactUsModal } from './components/ContactUsModal/ConatctUsModal'
import { MobileBottombar } from './components/MobileBottombar'
import { MobileHeader } from './components/MobileHeader'
import { NsfwDialog } from './components/NsfwDialog/NsfwDialog'
import { SignupMasker } from './components/SignupMasker/SignupMasker'
import { WebHeader } from './components/WebHeader'
import { WebSidebarMenu } from './components/WebSidebarMenu'
import { MOBILE_HEADER_HEIGHT, WEB_HEADER_HEIGHT } from './constants/layoutToken'
import styles from './index.module.css'
i18nInstance.addResourceBundle('en', 'home', enMessages)
i18nInstance.addResourceBundle('zh', 'home', zhMessages)

const DynamicNsfwDialog = dynamic(() => Promise.resolve(NsfwDialog), { ssr: false })
const DynamicSignupMasker = dynamic(() => Promise.resolve(SignupMasker), { ssr: false })
const DynamicInviteDialog = dynamic(() => Promise.resolve(InviteDialog), { ssr: false })
const DynamicUpgradeDialog = dynamic(() => Promise.resolve(UpgradeDialog), { ssr: false })
const DynamicAuthModal = dynamic(() => Promise.resolve(AuthModal), { ssr: false })
const DynamicVoiceUpgradeModal = dynamic(
  () => import('./components/VoiceUpgradeModal').then((mod) => mod.VoiceUpgradeModal),
  { ssr: false }
)

interface HomeLayoutProps {
  children?: React.ReactNode
}

export const HomeLayout = observer(({ children }: HomeLayoutProps) => {
  const isMobile = useIsMobile()

  return (
    <>
      {!isMobile && <WebSidebarMenu />}
      <div className="bg-coal-500 flex w-full flex-col">
        {isMobile ? <MobileHeader /> : <WebHeader />}
        {isMobile && <MobileBottombar />}
        <div
          className={cn(
            'bg-coal-500 transition-padding w-full overflow-y-auto bg-[url("https://api.mydreamboy.com/storage/v1/object/public/static/bg_noise.png")] duration-200',
            styles.container
          )}
          id="J_ScrollContainer"
          style={{
            paddingTop: isMobile ? MOBILE_HEADER_HEIGHT : WEB_HEADER_HEIGHT,
            paddingLeft: isMobile ? 0 : homeLayout$.webSidebarWidth.get(),
          }}
        >
          {children}
        </div>
      </div>
      <div>
        <DynamicNsfwDialog />
        <DynamicSignupMasker />
        <DynamicInviteDialog />
        <DynamicUpgradeDialog />
        <ContactUsModal />
        <PostDetailModal />
        <DynamicAuthModal />
        <DynamicVoiceUpgradeModal />
      </div>
    </>
  )
})
