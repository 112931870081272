import { cn } from '@my/magic-ui/src/lib/utils'
import { motion } from 'framer-motion'

export const Spinner = ({ className }: { className?: string }) => {
  return (
    <motion.div
      className={cn('h-6 w-6 rounded-full border-t-2 border-white', className)}
      animate={{ rotate: 360 }}
      transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
    />
  )
}

export const FullscreenSpinner = () => {
  return (
    <div className="flex h-full w-full flex-1 items-center justify-center">
      <Spinner />
    </div>
  )
}
