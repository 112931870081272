import { computed, observable } from '@legendapp/state'
import { isXlScreen } from 'app/hooks/useMediaQuery'

import { SIDEBAR_COMPACT_WIDTH, SIDEBAR_WIDTH } from '../HomeLayout/constants/layoutToken'

export enum AuthState {
  SIGN_IN = 'sign-in',
  SIGN_UP = 'sign-up',
  VERIFY_CODE = 'verify-code',
  CHANGE_PASSWORD = 'change-password',
  FORGET_PASSWORD = 'forget-password',
}

export const AuthBack: Record<AuthState, (() => void) | null> = {
  [AuthState.SIGN_IN]: null,
  [AuthState.SIGN_UP]: null,
  [AuthState.VERIFY_CODE]: () => homeLayout$.auth.state.set(AuthState.SIGN_UP),
  [AuthState.CHANGE_PASSWORD]: null,
  [AuthState.FORGET_PASSWORD]: () => homeLayout$.auth.state.set(AuthState.SIGN_IN),
}

export const homeLayout$ = observable({
  mobileTabShown: false,
  webSidebarWidth: isXlScreen() ? SIDEBAR_WIDTH : SIDEBAR_COMPACT_WIDTH,
  voiceUpgradeModalShown: false,

  auth: {
    show: false,
    state: AuthState.SIGN_IN,
    showBack: computed(() => AuthBack[homeLayout$.auth.state.get()] !== null),
  },
})

export const homeLayoutStore = {
  closeAuthModal: () => {
    homeLayout$.auth.show.set(false)
    homeLayout$.auth.state.set(AuthState.SIGN_IN)
  },

  openSignInModal: () => {
    homeLayout$.auth.show.set(true)
    homeLayout$.auth.state.set(AuthState.SIGN_IN)
  },

  openChangePasswordModal: () => {
    homeLayout$.auth.show.set(true)
    homeLayout$.auth.state.set(AuthState.CHANGE_PASSWORD)
  },

  showVoiceUpgradeModal: () => {
    homeLayout$.voiceUpgradeModalShown.set(true)
  },
}
