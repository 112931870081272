import { useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { useErrorToast } from 'app/hooks/useToast'
import { useSupabase } from 'app/utils/supabase/useSupabase'

export const useVerifyCode = () => {
  const loading$ = useObservable(false)
  const errorMessage$ = useObservable('')

  const supabase = useSupabase()
  const { toastError } = useErrorToast()

  const onVerifyCode = useMemoizedFn(
    async ({
      email,
      code,
      redirectTo,
      newsletter,
    }: {
      email: string
      code: string
      redirectTo?: string
      newsletter?: boolean
    }) => {
      if (!email) {
        toastError('Email missing, please try again')
        return
      }
      if (!code) {
        errorMessage$.set('Code are required')
        toastError('Code are required')
        return
      }
      loading$.set(true)
      const { error } = await supabase.auth.verifyOtp({
        email,
        token: code,
        type: 'email',
      })
      if (error) {
        errorMessage$.set(error.message ?? 'Something went wrong')
        toastError(error.message ?? 'Something went wrong')
      } else {
        let redirectUrl = `${location.origin}/api/auth/callback?signup_flow=true`
        if (redirectTo) {
          redirectUrl = `${redirectUrl}&redirect=${redirectTo}`
        }
        if (newsletter) {
          redirectUrl = `${redirectUrl}&newsletter=true`
        }
        window.location.href = redirectUrl
      }
      loading$.set(false)
    }
  )

  return {
    onVerifyCode,
    loading$,
    errorMessage$,
  }
}
