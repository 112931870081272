import { observer, useObservable } from '@legendapp/state/react'
import { ImageWithSkeleton } from 'app/components/ImageWithSkeleton'
import { useAlgImgUrlPrefix, useFallbackImgUrlPrefix } from 'app/utils/hooks/useAlgImgUrlPrefix'
import { useEffect } from 'react'

type Props = {
  item: { pid: string; width: number; height: number }
  noWatermark?: boolean
}

export const GeneratedImg = observer(({ item, noWatermark = false, ...props }: Props) => {
  const prefix = useAlgImgUrlPrefix()
  const fallbackPrefix = useFallbackImgUrlPrefix()
  const { pid } = item

  const prefix$ = useObservable(prefix)

  useEffect(() => {
    prefix$.set(noWatermark ? process.env.NEXT_PUBLIC_ALG_IMAGE_PREFIX : prefix)
  }, [noWatermark, prefix, prefix$])

  return (
    <ImageWithSkeleton
      src={`${prefix$.get()}${pid}`}
      onError={() => prefix$.set(fallbackPrefix)}
      {...props}
    />
  )
})
