import { Show, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { api } from 'app/utils/api'
import { useTranslation } from 'react-i18next'

import { modelSubscribeStore } from '../../../stores/modelSubscribe'
import { useModelSubscribeButton } from '../contexts/ModelSubscribeButtonContext'

export const UnSubscribeButton = () => {
  const modalOpen$ = useObservable(false)

  const [t] = useTranslation('modelSubscribeButton')
  const { unSubscribeButtonClassName, model, afterUnSubscribe, unSubscribeContent } =
    useModelSubscribeButton()

  const unSubscribe = api.model.unsubscribe.useMutation()
  const onUnSubscribe = () => {
    modelSubscribeStore.addUnsubscribe(model.id)
    unSubscribe.mutateAsync({ id: model.id }).catch(() => {
      modelSubscribeStore.removeSubscribe(model.id)
    })
    modalOpen$.set(false)
    afterUnSubscribe?.()
  }

  return (
    <>
      <Button
        mode="transparent"
        showArrow={false}
        className={cn('w-fit px-4 py-2 text-sm text-gray-600', unSubscribeButtonClassName)}
        onClick={() => modalOpen$.set(true)}
      >
        {unSubscribeContent ?? t('subscribed')}
      </Button>
      <Show if={modalOpen$}>
        {() => (
          <Modal
            isOpen={modalOpen$.get()}
            onClose={() => modalOpen$.set(false)}
            showCloseButton={false}
            mountToBody={false}
          >
            <div className="flex w-[300px] flex-col items-center gap-2 p-4 text-center">
              <div className="text-center text-xl font-bold">
                {t('unsubscribe')} <span className="max-w-32 truncate">@{model.name}</span>?
              </div>
              <div className="text-gray-700">{t('unSubscribeContent')}</div>
              <div className="mt-4 flex w-full flex-col gap-2">
                <Button
                  mode="white"
                  showArrow={false}
                  className="py-3 text-black"
                  onClick={onUnSubscribe}
                >
                  {t('unsubscribe')}
                </Button>
                <Button
                  mode="transparent"
                  showArrow={false}
                  className="py-3"
                  onClick={() => modalOpen$.set(false)}
                >
                  {t('cancel')}
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </Show>
    </>
  )
}
