import { motion } from 'framer-motion'
import { FC } from 'react'

interface CheckboxColors {
  // 勾选状态
  checkedBg?: string // 勾选时的背景色
  checkedBorder?: string // 勾选时的边框颜色
  // 未勾选状态
  uncheckedBg?: string // 未勾选时的背景色
  uncheckedBorder?: string // 未勾选时的边框颜色
  // 禁用状态
  disabledBg?: string // 禁用时的背景色
  disabledBorder?: string // 禁用时的边框颜色
  // 其他
  check?: string // 勾选图标颜色
  label?: string // 文字颜色
  disabledLabel?: string // 禁用时的文字颜色
}

const defaultColors: CheckboxColors = {
  checkedBg: '#3B82F6', // 蓝色背景
  checkedBorder: '#3B82F6', // 蓝色边框
  uncheckedBg: 'transparent', // 透明背景
  uncheckedBorder: '#9CA3AF', // gray-400 边框
  disabledBg: '#E5E7EB', // gray-200 背景
  disabledBorder: '#D1D5DB', // gray-300 边框
  check: 'white', // 白色勾选图标
  label: 'rgb(55 65 81)', // gray-700
  disabledLabel: 'rgb(156 163 175)', // gray-400
}

const checkVariants = {
  checked: {
    pathLength: 1.5,
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeOut' },
  },
  unchecked: {
    pathLength: 0,
    opacity: 0,
    transition: { duration: 0.3, ease: 'easeIn' },
  },
}

const boxVariants = {
  checked: ({ disabled, colors }: { disabled: boolean; colors: CheckboxColors }) => ({
    scale: 1,
    backgroundColor: disabled ? colors.disabledBg : colors.checkedBg,
    borderColor: disabled ? colors.disabledBorder : colors.checkedBorder,
    transition: { duration: 0.2, ease: [0.4, 0, 0.2, 1] },
  }),
  unchecked: ({ disabled, colors }: { disabled: boolean; colors: CheckboxColors }) => ({
    scale: 1,
    backgroundColor: disabled ? colors.disabledBg : colors.uncheckedBg,
    borderColor: disabled ? colors.disabledBorder : colors.uncheckedBorder,
    transition: { duration: 0.2, ease: [0.4, 0, 0.2, 1] },
  }),
  hover: {
    scale: 1.05,
  },
  tap: {
    scale: 0.95,
  },
}

interface CheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label?: string
  disabled?: boolean
  className?: string
  checkboxClassName?: string
  labelClassName?: string
  colors?: CheckboxColors
}

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  disabled = false,
  className = '',
  checkboxClassName = '',
  labelClassName = '',
  colors = {},
}) => {
  const mergedColors = { ...defaultColors, ...colors }

  return (
    <label
      className={`inline-flex cursor-pointer select-none items-center ${className}`}
      onClick={() => !disabled && onChange(!checked)}
    >
      <motion.div
        className={`relative h-5 w-5 overflow-hidden rounded-md border ${
          disabled ? 'cursor-not-allowed' : ''
        } ${checkboxClassName}`}
        variants={boxVariants}
        custom={{ disabled, colors: mergedColors }}
        animate={checked ? 'checked' : 'unchecked'}
        whileHover={disabled ? undefined : 'hover'}
        whileTap={disabled ? undefined : 'tap'}
        onClick={() => !disabled && onChange(!checked)}
      >
        <motion.svg
          className="absolute inset-0 h-full w-full"
          viewBox="0 0 24 24"
          initial={false}
          style={{ color: mergedColors.check }}
        >
          <motion.path
            d="M5 13l4 4L19 7"
            fill="transparent"
            strokeWidth="2"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            variants={checkVariants}
            animate={checked ? 'checked' : 'unchecked'}
          />
        </motion.svg>
      </motion.div>
      {label && (
        <motion.span
          className={`ml-2 ${labelClassName}`}
          style={{
            color: disabled ? mergedColors.disabledLabel : mergedColors.label,
          }}
          initial={false}
          animate={{
            x: checked ? [0, 2, 0] : 0,
          }}
          transition={{ duration: 0.2 }}
        >
          {label}
        </motion.span>
      )}
    </label>
  )
}
