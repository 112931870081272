import { observer, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { useMemoizedFn } from 'ahooks'
import { Button } from 'app/components/Button'
import { motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import { useVerifyCode } from './hooks/useVerifyCode'

interface VerifyCodeFormProps {
  className?: string
  email: string
  newsletter: boolean
  redirectTo?: string
  onResendCode: () => void
}

const codeLength = 6

export const VerifyCodeForm = observer(
  ({ email, redirectTo, onResendCode, className, newsletter }: VerifyCodeFormProps) => {
    const code$ = useObservable('')

    const { onVerifyCode, loading$, errorMessage$ } = useVerifyCode()

    const onVerify = useMemoizedFn(async () => {
      await onVerifyCode({ email, code: code$.get(), redirectTo, newsletter })
    })

    return (
      <div className={cn('font-grotesque px-6 py-8', className)}>
        <p className="font-grotesque text-center text-3xl font-bold">Verify your code</p>
        <p className="font-bold text-white/60">Enter verification code we sent you in the email</p>
        <VerificationInput
          className="mt-4"
          onChange={(code) => code$.set(code)}
          onComplete={onVerify}
        />
        {errorMessage$.get() && (
          <p className="text-sm text-red-500 md:text-base">{errorMessage$.get()}</p>
        )}
        <p
          className="font-grotesque mt-7 cursor-pointer text-lg font-bold text-white hover:underline"
          onClick={onResendCode}
        >
          Resend code
        </p>
        <Button
          className="mt-4 w-full bg-[#FFC24C] pb-3 pt-2 text-xl font-bold text-black"
          showArrow={false}
          mode="transparent"
          disabled={loading$.get() || code$.get().length !== codeLength}
          loading={loading$.get()}
          onClick={onVerify}
        >
          Verify
        </Button>
      </div>
    )
  }
)

interface VerificationInputProps {
  length?: number
  onChange?: (code: string) => void
  onComplete?: (code: string) => void
  className?: string
  inputClassName?: string
}

export const VerificationInput: React.FC<VerificationInputProps> = ({
  length = codeLength,
  onChange,
  onComplete,
  className = '',
  inputClassName = '',
}) => {
  const [code, setCode] = useState<string[]>(Array(length).fill(''))
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  // 初始化 ref 数组
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, length)
  }, [length])

  // 处理值变化
  const handleChange = (index: number, value: string) => {
    // validate value, only number
    if (!/^\d*$/.test(value)) {
      return
    }

    const newCode = [...code]
    // 确保只取一个字符
    newCode[index] = value.slice(-1)
    setCode(newCode)

    // 触发 onChange
    const joinedCode = newCode.join('')
    onChange?.(joinedCode)

    // 如果输入完成，触发 onComplete
    if (joinedCode.length === length && !newCode.includes('')) {
      onComplete?.(joinedCode)
    }

    // 自动聚焦下一个输入框
    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  // 处理键盘事件
  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      // 如果当前输入框为空且按下删除键，聚焦到上一个输入框
      inputRefs.current[index - 1]?.focus()
    }
  }

  // 处理粘贴事件
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text').slice(0, length)
    // validate pastedData, only number
    if (!/^\d*$/.test(pastedData)) {
      return
    }
    const newCode = [...code]

    for (let i = 0; i < pastedData.length; i++) {
      if (i < length) {
        newCode[i] = pastedData[i]
      }
    }

    setCode(newCode)

    // 触发 onChange
    const joinedCode = newCode.join('')
    onChange?.(joinedCode)

    // 如果输入完成，触发 onComplete
    if (joinedCode.length === length && !newCode.includes('')) {
      onComplete?.(joinedCode)
    }

    // 聚焦到最后一个已填充的输入框的下一个
    const nextEmptyIndex = newCode.findIndex((x) => !x)
    if (nextEmptyIndex !== -1 && nextEmptyIndex < length) {
      inputRefs.current[nextEmptyIndex]?.focus()
    }
  }

  return (
    <div className={`flex justify-around ${className}`}>
      {Array(length)
        .fill(0)
        .map((_, index) => (
          <motion.div
            key={index}
            initial={{ scale: 1 }}
            animate={{ scale: code[index] ? 1.05 : 1 }}
            transition={{ duration: 0.1 }}
          >
            <input
              ref={(el) => {
                inputRefs.current[index] = el
              }}
              type="text"
              inputMode="numeric"
              pattern="\d*"
              maxLength={1}
              value={code[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              onPaste={handlePaste}
              className={`h-11 w-11 rounded-lg border border-solid border-white/20 bg-[#35353533]/20 text-center text-xl text-white outline-none transition-colors focus:border-gray-500 disabled:cursor-not-allowed disabled:opacity-50 ${inputClassName}`}
            />
          </motion.div>
        ))}
    </div>
  )
}
