import { observer } from '@legendapp/state/react'
import { i18nInstance } from 'app/utils/i18n'
import { useUser } from 'app/utils/useUser'

import { GeneratedImg } from './GeneratedImg'
import { AsAvatarButton } from './components/AsAvatarButton'
import { DeleteButton } from './components/DeleteButton'
import { GeneratedImgWithOPProvider, IProps } from './context/GeneratedImgWithOPContext'
import enMessages from '../locale/en/generateImage.json'
import zhMessages from '../locale/zh/generateImage.json'
i18nInstance.addResourceBundle('en', 'generateImage', enMessages)
i18nInstance.addResourceBundle('zh', 'generateImage', zhMessages)

type Props = IProps

export const GeneratedImgWithOP = observer(
  ({
    image,
    post,
    model,
    showAvatarBtn,
    showTemplateBtn,
    onDelete,
    showDeleteBtn,
    ...props
  }: Props) => {
    const { isLogin } = useUser()
    return (
      <GeneratedImgWithOPProvider
        image={image}
        post={post}
        model={model}
        showAvatarBtn={showAvatarBtn ?? true}
        showTemplateBtn={showTemplateBtn ?? true}
        showDeleteBtn={showDeleteBtn ?? false}
        onDelete={onDelete}
      >
        <div className="relative h-full w-full">
          <GeneratedImg item={image} {...props} />
          <div className="absolute right-3 top-3 flex items-center gap-3 md:right-6 md:top-6">
            {isLogin && <AsAvatarButton />}
            {showDeleteBtn && <DeleteButton />}
          </div>
        </div>
      </GeneratedImgWithOPProvider>
    )
  }
)
