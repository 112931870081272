import { observer } from '@legendapp/state/react'

import { store$ } from '../context/PostDetailModelContext'

export const PostContent = observer(() => {
  const post = store$.post.get()
  const content = post.content

  if (!content) return null
  return (
    <div className="flex">
      <p>{content}</p>
    </div>
  )
})
