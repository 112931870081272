import { observer, useSelector } from '@legendapp/state/react'
import { ModelAvatar } from 'app/features/models/ModelAvatar/ModelAvatar'
import { ModelSubscribeButton } from 'app/features/models/components/ModelSubscribeButton'
import Link from 'next/link'

import { store$ } from '../context/PostDetailModelContext'

export const PostModelInfo = observer(() => {
  const info = useSelector(() => store$.model.get())
  if (!info) return null
  return (
    <div className="flex h-12 flex-shrink-0 items-center justify-between gap-2">
      <Link href={`/model/${info.id}`} style={{ textDecoration: 'none' }}>
        <div className="flex items-center gap-2">
          <ModelAvatar pid={info.avatar!} modelId={info.id!} className="h-8 w-8" />
          <p className="max-w-32 truncate text-xs">{info.nickname}</p>
        </div>
      </Link>
      <ModelSubscribeButton
        model={{ name: info.nickname!, id: info.id! }}
        subscribed={!!info.subscribed}
        subscribeButtonClassName="px-2 py-1 text-xs"
        unSubscribeButtonClassName="px-2 py-1 text-xs"
      />
    </div>
  )
})
