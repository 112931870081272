import constate from 'constate'

export type ModelSubscribeButtonProps = {
  model: { id: string; name: string }
  subscribed: boolean
  subscribeButtonClassName?: string
  unSubscribeButtonClassName?: string
  afterSubscribe?: () => void
  afterUnSubscribe?: () => void
  subscribeContent?: React.ReactNode
  unSubscribeContent?: React.ReactNode
}

const useModelSubscribeButtonInner = (props: ModelSubscribeButtonProps) => {
  return {
    ...props,
  }
}

export const [ModelSubscribeButtonProvider, useModelSubscribeButton] = constate(
  useModelSubscribeButtonInner
)
