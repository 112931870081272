import clsx from 'clsx' // 推荐使用clsx来合并className
import React from 'react'

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  value: string | undefined
  onChange: (value: string) => void
  // 新增各部分的className
  wrapperClassName?: string
  inputClassName?: string
  leftIconClassName?: string
  rightIconClassName?: string
}

const Input: React.FC<InputProps> = ({
  leftIcon,
  rightIcon,
  value,
  onChange,
  className, // 保留原有的className用于向后兼容
  wrapperClassName,
  inputClassName,
  leftIconClassName,
  rightIconClassName,
  ...rest
}) => {
  return (
    <div className={clsx('relative flex w-full items-center', wrapperClassName)}>
      {leftIcon && (
        <span
          className={clsx(
            'absolute left-3 top-1/2 -translate-y-1/2 text-gray-400',
            leftIconClassName
          )}
        >
          {leftIcon}
        </span>
      )}
      <input
        className={clsx(
          'w-full cursor-pointer bg-gray-100 px-3 py-2',
          'rounded-md border border-gray-300',
          'text-sm text-gray-900',
          'transition-all duration-300',
          'hover:bg-gray-200 active:bg-gray-200',
          'disabled:cursor-not-allowed disabled:bg-gray-100',
          leftIcon ? 'pl-10' : '',
          rightIcon ? 'pr-10' : '',
          className, // 为了向后兼容
          inputClassName
        )}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        {...rest}
      />
      {rightIcon && (
        <span
          className={clsx(
            'absolute right-3 top-1/2 -translate-y-1/2 text-gray-400',
            rightIconClassName
          )}
        >
          {rightIcon}
        </span>
      )}
    </div>
  )
}

export default Input
