import { observer, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src/lib/utils'
import { motion, AnimatePresence } from 'framer-motion'
import { ImageOff } from 'lucide-react'
import { useEffect } from 'react'
export const ImageWithSkeleton = observer(
  ({
    src,
    alt,
    className,
    imgClassName,
    onError,
  }: {
    src: string
    alt?: string
    className?: string
    imgClassName?: string
    onError?: () => void
  }) => {
    const status$ = useObservable<'loading' | 'loaded' | 'error'>('loading')

    useEffect(() => {
      if (src) {
        status$.set('loading')
      }
    }, [src, status$])

    if (status$.get() === 'error') {
      return (
        <div className={cn('flex h-full w-full flex-1 items-center justify-center', className)}>
          <ImageOff size={24} />
        </div>
      )
    }

    return (
      <div className={cn('relative h-full w-full flex-1', className)}>
        <AnimatePresence>
          {status$.get() === 'loading' && (
            <motion.div
              key={`skeleton-${src}`}
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0"
            >
              <div className="relative h-full w-full bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200">
                <motion.div
                  key={`skeleton-animation-${src}`}
                  className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent"
                  animate={{
                    x: ['-100%', '100%'],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: 'linear',
                    repeatType: 'loop',
                  }}
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.img
          src={src}
          style={{
            opacity: status$.get() === 'loaded' ? 1 : 0,
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: status$.get() === 'loaded' ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          onLoad={() => status$.set('loaded')}
          alt={alt}
          className={cn('h-full w-full object-contain', imgClassName)}
          onError={() => {
            status$.set('error')
            onError?.()
          }}
        />
      </div>
    )
  }
)
