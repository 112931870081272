import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0001 12C7.0001 12.9389 6.23898 13.7 5.3001 13.7C4.36121 13.7 3.6001 12.9389 3.6001 12C3.6001 11.0612 4.36121 10.3 5.3001 10.3C6.23898 10.3 7.0001 11.0612 7.0001 12ZM13.7003 12C13.7003 12.9389 12.9392 13.7 12.0003 13.7C11.0614 13.7 10.3003 12.9389 10.3003 12C10.3003 11.0612 11.0614 10.3 12.0003 10.3C12.9392 10.3 13.7003 11.0612 13.7003 12ZM18.7 13.7C19.6389 13.7 20.4 12.9389 20.4 12C20.4 11.0612 19.6389 10.3 18.7 10.3C17.7611 10.3 17 11.0612 17 12C17 12.9389 17.7611 13.7 18.7 13.7Z"
      fill="currentColor"
    />
  </svg>
)
export const IconDotsHorizontal = memo(SvgComponent)
