import { Observable } from '@legendapp/state'
import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { useIsPrivatePost } from 'app/features/posts/hooks/usePostStatus'
import { privatePostIds$, publicPostIds$ } from 'app/features/posts/stores/operatedPosts'
import { api } from 'app/utils/api'
import { AlertCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { store$ } from '../../../context/PostDetailModelContext'

export const ChangeScopeDialog = observer(({ open$ }: { open$: Observable<boolean> }) => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'scopeDialog' })
  const postId = Number(store$.post.id.get())
  const isPrivatePost = useIsPrivatePost(store$.post.get())

  const updateMutation = api.post.update.useMutation()

  const onUpdate = () => {
    updateMutation.mutateAsync({ id: postId, private: !isPrivatePost })
    if (isPrivatePost) {
      publicPostIds$.add(postId)
      privatePostIds$.delete(postId)
    } else {
      publicPostIds$.delete(postId)
      privatePostIds$.add(postId)
    }
    open$.set(false)
  }

  return (
    <Modal
      isOpen={open$.get()}
      onClose={() => open$.set(false)}
      showCloseButton={false}
      mountToBody={false}
    >
      <div className="relative flex w-[90vw] max-w-[400px] flex-col items-center gap-2 p-4 text-center">
        <AlertCircle className="h-8 w-8 text-[#FFC24C]" />
        <p className="text-lg font-bold">{isPrivatePost ? t('public') : t('private')}</p>
        <p className="text-sm text-gray-800">
          {isPrivatePost ? t('publicDesc') : t('privateDesc')}
        </p>
        <div className="mt-4 flex gap-2">
          <Button
            mode="white"
            showArrow={false}
            onClick={onUpdate}
            className="w-auto px-4 py-2 text-sm font-bold text-black md:text-base"
          >
            {t('confirm')}
          </Button>
          <Button
            mode="transparent"
            showArrow={false}
            onClick={() => open$.set(false)}
            className="w-auto px-4 py-2 text-sm font-bold md:text-base"
          >
            {t('cancel')}
          </Button>
        </div>
      </div>
    </Modal>
  )
})
