import { useObservable, useObserve } from '@legendapp/state/react'
import { useCheckLogin } from 'app/features/auth/hooks/useCheckLogin'
import {
  addGlobalPostDislike,
  addGlobalPostLike,
  globalPost$,
  resetGLobalPostLike,
} from 'app/stores/postStore'
import { api } from 'app/utils/api'

import { IPost } from '../typings/post'

export const usePostLike = () => {
  const mutation = api.post.like.useMutation()
  const { checkLogin } = useCheckLogin()

  return {
    setPostLike: async (post: IPost) => {
      if (checkLogin(true)) {
        addGlobalPostLike(post.id)
        await mutation.mutateAsync({ id: Number(post.id) }).catch((err) => {
          resetGLobalPostLike(post.id)
          throw err
        })
      }
    },
  }
}

export const usePostDislike = () => {
  const mutation = api.post.dislike.useMutation()
  const { checkLogin } = useCheckLogin()

  return {
    setPostDislike: async (post: IPost) => {
      if (checkLogin(true)) {
        addGlobalPostDislike(post.id)
        await mutation.mutateAsync({ id: Number(post.id) }).catch((err) => {
          resetGLobalPostLike(post.id)
          throw err
        })
      }
    },
  }
}

export const usePostLikeStatus = (post: IPost) => {
  const like$ = useObservable(post.like)
  useObserve(() => {
    if (globalPost$.likes.get().includes(post.id)) {
      like$.set(true)
    }
    if (globalPost$.dislikes.get().includes(post.id)) {
      like$.set(false)
    }
  })
  return like$
}

export const usePostLikeCount = (post: IPost) => {
  const likeCount$ = useObservable(post.likes ?? 0)

  useObserve(() => {
    if (globalPost$.likes.get().includes(post.id) && !post.like) {
      const result = likeCount$.peek() + 1
      likeCount$.set(result >= 0 ? result : 0)
    } else if (globalPost$.dislikes.get().includes(post.id) && post.like) {
      const result = likeCount$.peek() - 1
      likeCount$.set(result >= 0 ? result : 0)
    } else {
      likeCount$.set(post.likes)
    }
  })
  return likeCount$
}
