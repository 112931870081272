import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4113 6.0762C14.4113 8.52373 12.4489 10.4862 9.99967 10.4862C7.55126 10.4862 5.58802 8.52373 5.58802 6.0762C5.58802 3.62868 7.55126 1.66699 9.99967 1.66699C12.4489 1.66699 14.4113 3.62868 14.4113 6.0762ZM9.99967 18.3337C6.38499 18.3337 3.33301 17.7462 3.33301 15.4795C3.33301 13.2119 6.40416 12.6453 9.99967 12.6453C13.6152 12.6453 16.6663 13.2328 16.6663 15.4995C16.6663 17.767 13.5952 18.3337 9.99967 18.3337Z"
      fill="currentColor"
    />
  </svg>
)
export const IconProfile = memo(SvgComponent)
