import { cn } from '@my/magic-ui/src/lib/utils'
import { useMemoizedFn } from 'ahooks'
import { motion, useMotionValue, useTransform, type PanInfo } from 'framer-motion'
import type React from 'react'
import { useRef, useState, useEffect, useMemo, useCallback } from 'react'

interface CustomScrollContainerProps {
  children: React.ReactNode
  className?: string
  hideScrollbar?: boolean
  scrollbarSize?: number
  trackClassName?: string
  thumbClassName?: string
}

export default function CustomScrollContainer({
  children,
  className = '',
  hideScrollbar = false,
  scrollbarSize = 6,
  trackClassName = '',
  thumbClassName = '',
}: CustomScrollContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const scrollXProgress = useMotionValue(0)
  const scrollYProgress = useMotionValue(0)

  const [dimensions, setDimensions] = useState({
    containerWidth: 0,
    containerHeight: 0,
    contentWidth: 0,
    contentHeight: 0,
  })
  const [showHorizontal, setShowHorizontal] = useState(false)
  const [showVertical, setShowVertical] = useState(false)
  const [isDraggingX, setIsDraggingX] = useState(false)
  const [isDraggingY, setIsDraggingY] = useState(false)

  // Memoize calculated values
  const { horizontalThumbWidth, verticalThumbHeight } = useMemo(
    () => ({
      horizontalThumbWidth:
        dimensions.containerWidth > 0 && dimensions.contentWidth > 0
          ? Math.max(
              (dimensions.containerWidth / dimensions.contentWidth) * dimensions.containerWidth,
              40
            )
          : 0,
      verticalThumbHeight:
        dimensions.containerHeight > 0 && dimensions.contentHeight > 0
          ? Math.max(
              (dimensions.containerHeight / dimensions.contentHeight) * dimensions.containerHeight,
              40
            )
          : 0,
    }),
    [dimensions]
  )

  const scrollbarXPosition = useTransform(
    scrollXProgress,
    [0, 1],
    [0, dimensions.containerWidth - horizontalThumbWidth]
  )

  const scrollbarYPosition = useTransform(
    scrollYProgress,
    [0, 1],
    [0, dimensions.containerHeight - verticalThumbHeight]
  )

  const updateDimensions = useMemoizedFn(() => {
    if (containerRef.current && contentRef.current) {
      const containerWidth = containerRef.current.clientWidth
      const containerHeight = containerRef.current.clientHeight
      const contentWidth = contentRef.current.scrollWidth
      const contentHeight = contentRef.current.scrollHeight

      setDimensions({
        containerWidth,
        containerHeight,
        contentWidth,
        contentHeight,
      })

      setShowHorizontal(contentWidth > containerWidth)
      setShowVertical(contentHeight > containerHeight)
    }
  })

  useEffect(() => {
    updateDimensions()

    const resizeObserver = new ResizeObserver(updateDimensions)
    if (containerRef.current) resizeObserver.observe(containerRef.current)
    if (contentRef.current) resizeObserver.observe(contentRef.current)

    setTimeout(() => {
      updateDimensions()
    }, 300)

    window.addEventListener('resize', updateDimensions)

    return () => {
      resizeObserver.disconnect()
      window.removeEventListener('resize', updateDimensions)
    }
  }, [updateDimensions])

  const handleContentScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.currentTarget
      if (dimensions.contentWidth > dimensions.containerWidth) {
        const scrollLeftMax = dimensions.contentWidth - dimensions.containerWidth
        const newXProgress = target.scrollLeft / scrollLeftMax
        scrollXProgress.set(newXProgress)
      }

      if (dimensions.contentHeight > dimensions.containerHeight) {
        const scrollTopMax = dimensions.contentHeight - dimensions.containerHeight
        const newYProgress = target.scrollTop / scrollTopMax
        scrollYProgress.set(newYProgress)
      }

      updateDimensions()
    },
    [
      dimensions.containerHeight,
      dimensions.containerWidth,
      dimensions.contentHeight,
      dimensions.contentWidth,
      scrollXProgress,
      scrollYProgress,
      updateDimensions,
    ]
  )

  const handleHorizontalDrag = useCallback(
    (_: MouseEvent, info: PanInfo) => {
      if (contentRef.current && dimensions.contentWidth > dimensions.containerWidth) {
        const scrollLeftMax = dimensions.contentWidth - dimensions.containerWidth
        const newScrollLeft = Math.max(
          0,
          Math.min(
            scrollLeftMax,
            scrollLeftMax * scrollXProgress.get() +
              info.delta.x * (dimensions.contentWidth / dimensions.containerWidth)
          )
        )

        contentRef.current.scrollLeft = newScrollLeft
        scrollXProgress.set(newScrollLeft / scrollLeftMax)
      }
    },
    [dimensions, scrollXProgress]
  )

  const handleVerticalDrag = useCallback(
    (_: MouseEvent, info: PanInfo) => {
      if (contentRef.current && dimensions.contentHeight > dimensions.containerHeight) {
        const scrollTopMax = dimensions.contentHeight - dimensions.containerHeight
        const newScrollTop = Math.max(
          0,
          Math.min(
            scrollTopMax,
            scrollTopMax * scrollYProgress.get() +
              info.delta.y * (dimensions.contentHeight / dimensions.containerHeight)
          )
        )

        contentRef.current.scrollTop = newScrollTop
        scrollYProgress.set(newScrollTop / scrollTopMax)
      }
    },
    [dimensions, scrollYProgress]
  )

  useEffect(() => {
    const syncScrollPosition = () => {
      if (contentRef.current) {
        if (dimensions.contentWidth > dimensions.containerWidth) {
          const scrollLeftMax = dimensions.contentWidth - dimensions.containerWidth
          const newScrollLeft = scrollLeftMax * scrollXProgress.get()
          if (contentRef.current.scrollLeft !== newScrollLeft) {
            contentRef.current.scrollLeft = newScrollLeft
          }
        }

        if (dimensions.contentHeight > dimensions.containerHeight) {
          const scrollTopMax = dimensions.contentHeight - dimensions.containerHeight
          const newScrollTop = scrollTopMax * scrollYProgress.get()
          if (contentRef.current.scrollTop !== newScrollTop) {
            contentRef.current.scrollTop = newScrollTop
          }
        }
      }
    }

    const unsubscribeX = scrollXProgress.onChange(syncScrollPosition)
    const unsubscribeY = scrollYProgress.onChange(syncScrollPosition)

    return () => {
      unsubscribeX()
      unsubscribeY()
    }
  }, [dimensions, scrollXProgress, scrollYProgress])

  return (
    <div
      ref={containerRef}
      className={`relative overflow-hidden ${className}`}
      style={{ width: '100%', height: '100%' }}
    >
      <div
        ref={contentRef}
        className={cn('scrollbar-hide h-full w-full overflow-auto')}
        onScroll={handleContentScroll}
        style={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {children}
      </div>

      {showHorizontal && !hideScrollbar && (
        <div
          className={cn('absolute bottom-0 left-0 right-0', trackClassName)}
          style={{
            height: scrollbarSize,
            zIndex: 10,
            marginRight: showVertical ? scrollbarSize : 0,
            opacity: isDraggingX ? 1 : 0.6,
            transition: 'opacity 0.2s ease-in-out',
          }}
        >
          <motion.div
            className={cn(
              'absolute top-0 cursor-pointer rounded-full bg-[#FFC24C]',
              thumbClassName
            )}
            style={{
              width: horizontalThumbWidth,
              height: scrollbarSize,
              x: scrollbarXPosition,
            }}
            drag="x"
            dragConstraints={{
              left: 0,
              right: dimensions.containerWidth - horizontalThumbWidth,
            }}
            dragElastic={0}
            dragMomentum={false}
            onDrag={handleHorizontalDrag}
            onDragStart={() => setIsDraggingX(true)}
            onDragEnd={() => setIsDraggingX(false)}
          />
        </div>
      )}

      {showVertical && !hideScrollbar && (
        <div
          className={cn('absolute bottom-0 right-0 top-0', trackClassName)}
          style={{
            width: scrollbarSize,
            zIndex: 10,
            marginBottom: showHorizontal ? scrollbarSize : 0,
            opacity: isDraggingY ? 1 : 0.6,
            transition: 'opacity 0.2s ease-in-out',
          }}
        >
          <motion.div
            className={cn(
              'absolute left-0 cursor-pointer rounded-full bg-[#FFC24C]',
              thumbClassName
            )}
            style={{
              width: scrollbarSize,
              height: verticalThumbHeight,
              y: scrollbarYPosition,
            }}
            drag="y"
            dragConstraints={{
              top: 0,
              bottom: dimensions.containerHeight - verticalThumbHeight,
            }}
            dragElastic={0}
            dragMomentum={false}
            onDrag={handleVerticalDrag}
            onDragStart={() => setIsDraggingY(true)}
            onDragEnd={() => setIsDraggingY(false)}
          />
        </div>
      )}
    </div>
  )
}
