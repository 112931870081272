import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share'

export const SocialShare = ({
  url,
  title,
  size = 24,
  onShareWindowClose,
}: {
  url: string
  title: string
  size?: number
  onShareWindowClose?: () => void
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      <ChannelBox>
        <IconWrapper>
          <TwitterShareButton
            url={url}
            title={title}
            via="mydreamboyPr"
            hashtags={['NSFW', 'NSFWAI', 'Man', 'Porn']}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <XIcon round size={size} />
          </TwitterShareButton>
        </IconWrapper>
        <ChannelText>Twitter</ChannelText>
      </ChannelBox>
      <ChannelBox>
        <IconWrapper>
          <FacebookShareButton
            url={url}
            title={title}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <FacebookIcon round size={size} />
          </FacebookShareButton>
        </IconWrapper>
        <ChannelText>Facebook</ChannelText>
      </ChannelBox>
      <ChannelBox>
        <IconWrapper>
          <RedditShareButton
            url={url}
            windowWidth={660}
            windowHeight={460}
            title={title}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <RedditIcon size={size} round />
          </RedditShareButton>
        </IconWrapper>
        <ChannelText>Reddit</ChannelText>
      </ChannelBox>
      <ChannelBox>
        <IconWrapper>
          <TelegramShareButton
            url={url}
            title={title}
            onShareWindowClose={() => onShareWindowClose?.()}
          >
            <TelegramIcon size={size} round />
          </TelegramShareButton>
        </IconWrapper>
        <ChannelText>Telegram</ChannelText>
      </ChannelBox>
    </div>
  )
}
const IconWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex aspect-square w-11 items-center justify-center rounded-full hover:bg-gray-300">
      {children}
    </div>
  )
}

const ChannelText = ({ children }: { children: React.ReactNode }) => {
  return <p className="text-sm font-[500] text-gray-800">{children}</p>
}

const ChannelBox = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col items-center gap-2">{children}</div>
}
