import { useMemoizedFn } from 'ahooks'
import { modelPlayStore } from 'app/features/models/stores/modelPlay'
import { useUser } from 'app/utils/useUser'
import { useRouter } from 'next/router'

import { store$ } from '../../../context/PostDetailModelContext'

export const useGenerateMore = () => {
  const router = useRouter()
  const image = store$.post.images[0]?.get()
  const { user } = useUser()
  const onGenerateMore = useMemoizedFn(() => {
    // generate more set with prompt and user tags
    if (store$.post.creator.get() === user?.id) {
      modelPlayStore.state$.generateMore.customizePrompt.set(
        store$.post.customizePrompt.get() ?? store$.post.customize_prompt.get() ?? ''
      )
      modelPlayStore.state$.generateMore.userTags.set(store$.post.userTagsVO.get() ?? [])
    }

    if (!store$.model.id.peek()) return
    if (store$.model.version.peek() === 'v2') {
      router.push(`/model/${store$.model.id.peek()}/play`)
    } else {
      router.push(
        `/model/${store$.model.id.peek()}/play?combos=${encodeURIComponent(
          JSON.stringify(store$.post.tag_combos.peek())
        )}&version=${store$.model.version.peek()}&width=${image?.width}&height=${image?.height}`
      )
    }
  })
  return { onGenerateMore }
}
