import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src/lib/utils'
import { Button } from 'app/components/Button'
import { Spinner } from 'app/components/Spinner'
import { useCheckLogin } from 'app/features/auth/hooks/useCheckLogin'
import { IconUserTier } from 'app/features/ecommerce/components/IconUserTier'
import { UserAvatar } from 'app/features/users/components/UserAvatar'
import { useUserCredits } from 'app/features/users/hooks/useUserCredits'
import { useUserSubscribed } from 'app/features/users/hooks/useUserSubscribed'
import { useUser } from 'app/utils/useUser'

import { useOptions } from '../hooks/useOptions'

const UserInfos = observer(() => {
  const { creditsLeft$ } = useUserCredits()
  const { subscribed$ } = useUserSubscribed()
  const { isLogin, profile$, user$ } = useUser()

  if (!isLogin) return null

  if (user$.isLoading.get())
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    )

  return (
    <>
      <div className="flex items-center gap-3 px-4">
        <UserAvatar useSelfInfo className="h-10 w-10" />
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-1">
            <p className="font-grotesque max-w-32 truncate text-lg font-bold leading-6">
              {profile$.name.get()}
            </p>
            {subscribed$.get() && <IconUserTier className="mt-1 h-4 w-4" />}
          </div>
          <div className="text-sm leading-4 text-white/60">{creditsLeft$.get()} credits left</div>
        </div>
      </div>
      <Divider />
    </>
  )
})

export const MenuOptions = observer(() => {
  const options = useOptions()
  const { isLogin } = useUser()

  const { checkLogin } = useCheckLogin()

  return (
    <div className="py-6">
      <UserInfos />
      {options.map((option, index) => {
        if (index === 0 && !isLogin)
          return (
            <div>
              <Button
                key="login"
                mode="transparent"
                className="mx-auto w-auto rounded-lg bg-[#FFC24C]/90 px-20 py-2 text-sm font-bold text-black md:text-base"
                showArrow={false}
                onClick={() => {
                  checkLogin()
                }}
              >
                Login
              </Button>
              {index !== options.length - 1 && <Divider className="mb-3 mt-6" />}
            </div>
          )
        return (
          <>
            <div key={index} className="flex flex-col gap-5">
              {option.map((item) => (
                <div
                  key={item.label}
                  className="flex cursor-pointer items-center gap-3 px-4 hover:text-[#FFC24C]"
                  onClick={item.onClick}
                >
                  <item.icon className="h-5 w-5" />
                  <p className="font-grotesque text-base font-[600] leading-5 tracking-wide md:text-lg">
                    {item.label}
                  </p>
                </div>
              ))}
            </div>
            {index !== options.length - 1 && <Divider className="mb-3 mt-6" />}
          </>
        )
      })}
    </div>
  )
})

const Divider = ({ className }: { className?: string }) => {
  return <div className={cn('my-6 h-[1px] w-full bg-white/20', className)} />
}
