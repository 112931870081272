import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { useMemoizedFn } from 'ahooks'
import { Button } from 'app/components/Button'
import { useForm } from 'react-hook-form'

import { FormField } from './components/FormField'
import { GoogleSignInButton } from './components/GoogleSignInButton'
import { TermsAndNewsletter } from './components/TermsAndNewsletter'
import { useSignIn } from './hooks/useSignIn'

interface SignInFormProps {
  className?: string
  afterSignIn: () => void
  googleRedirectTo?: string
  onForgotPassword: () => void
  onSignUp: () => void
}

interface FormData {
  email: string
  password: string
}

export const SignInForm = observer(
  ({ afterSignIn, googleRedirectTo, onForgotPassword, onSignUp, className }: SignInFormProps) => {
    const { onSignIn, loading$ } = useSignIn()

    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm<FormData>({
      defaultValues: {
        email: '',
        password: '',
      },
    })

    const onSubmit = useMemoizedFn(async (data: FormData) => {
      await onSignIn({
        email: data.email,
        password: data.password,
        setError,
        afterSignIn,
      })
    })

    return (
      <div className={cn('font-grotesque relative z-50 px-6 py-8', className)}>
        <p className="font-grotesque text-center text-3xl font-bold">Sign in to your account</p>
        <form className="mt-4 space-y-2">
          <FormField
            label="Email"
            type="email"
            placeholder="Enter your email"
            error={errors.email}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Please enter a valid email',
              },
            })}
          />
          <FormField
            label="Password"
            type="password"
            placeholder="Enter your password"
            error={errors.password}
            {...register('password', {
              required: 'Password is required',
              minLength: { value: 6, message: 'Password must be at least 6 characters' },
            })}
          />
        </form>
        <p
          className="font-grotesque mt-2 cursor-pointer font-bold tracking-[0.32px] text-white/60 hover:underline"
          onClick={onForgotPassword}
        >
          Forgot your password?
        </p>
        <Button
          className="font-grotesque mt-4 w-full bg-[#FFC24C] pb-3 pt-2 text-xl font-bold text-black"
          showArrow={false}
          mode="transparent"
          type="submit"
          disabled={loading$.get()}
          loading={loading$.get()}
          onClick={handleSubmit(onSubmit)}
        >
          Sign in
        </Button>
        <p className="font-grotesque mt-3 text-center text-lg font-bold text-white">
          Don’t have an account yet?{' '}
          <span className="cursor-pointer text-[#FFC24C] underline" onClick={onSignUp}>
            Sign up
          </span>
        </p>
        <p className="mt-4 text-center font-bold text-white/40">OR</p>
        <GoogleSignInButton className="mt-4" redirectTo={googleRedirectTo} />
        <TermsAndNewsletter className="mt-4" />
      </div>
    )
  }
)
