import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { Checkbox } from 'app/components/Checkbox'
import { motion } from 'framer-motion'

import { useTermsAndNewsletterCheck } from '../hooks/useTermsAndNewsletterCheck'

const colors = {
  checkedBg: '#FFC24C',
  checkedBorder: 'transparent',
  uncheckedBg: 'rgba(218, 214, 195, 0.10)',
  uncheckedBorder: '#DAD6C3',
}

// 抖动动画变体
const shakeVariants = {
  shake: {
    x: [0, -10, 10, -10, 10, 0],
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
}

export const TermsAndNewsletter = observer(({ className }: { className?: string }) => {
  const { termsChecked$, newsletterChecked$, termsShake$ } = useTermsAndNewsletterCheck()
  return (
    <div className={cn('font-grotesque flex flex-col gap-2 text-sm', className)}>
      <motion.div
        className="flex items-center gap-2"
        variants={shakeVariants}
        animate={termsShake$.get() ? 'shake' : ''}
      >
        <Checkbox
          checked={termsChecked$.get()}
          onChange={(v) => {
            termsChecked$.set(v)
          }}
          checkboxClassName="w-4 h-4 bg-[#FFC24C]"
          colors={colors}
        />
        <p className="break-words">
          By signing in, you agree to our{' '}
          <a href="/terms-of-service" target="_blank" className="text-[#FFC24C]">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" target="_blank" className="text-[#FFC24C]">
            Privacy Policy
          </a>
        </p>
      </motion.div>
      <div className="flex items-center gap-2">
        <Checkbox
          checked={newsletterChecked$.get()}
          onChange={(v) => {
            newsletterChecked$.set(v)
          }}
          checkboxClassName="w-4 h-4 bg-[#FFC24C]"
          colors={colors}
        />
        <p>Keep me updated with news and offers</p>
      </div>
    </div>
  )
})
