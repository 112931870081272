import { useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { useErrorToast } from 'app/hooks/useToast'
import { useSupabase } from 'app/utils/supabase/useSupabase'

export const useResetPassword = () => {
  const loading$ = useObservable(false)
  const success$ = useObservable(false)

  const supabase = useSupabase()
  const { toastError } = useErrorToast()

  const onResetPassword = useMemoizedFn(async ({ email }: { email: string }) => {
    loading$.set(true)
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${location.origin}/`,
    })
    if (error) {
      toastError(error.message ?? 'Something went wrong')
    } else {
      success$.set(true)
    }
    loading$.set(false)
  })

  return {
    onResetPassword,
    loading$,
    success$,
  }
}
