import { CommentMetaData_Fragement } from '@my/api/src/routers/comment/graphql/index'
import { getFragmentData } from '@my/supabase/graphql/gql'
import { LikeButton } from 'app/components/LikeButton'
import { UserAvatar } from 'app/features/users/UserAvatar/UserAvatar'
import { MessageCircle } from 'lucide-react'
import Link from 'next/link'
import { useMemo, useState } from 'react'

import { CommentList } from './CommentList'
import { TimeAgo } from './TimeAgo'
import { editor$ } from '../../Editor/store/editor'
import { IComment } from '../../typings/interface'
import { useToggleCommentLike } from '../hooks/useToggleCommentLike'

interface Props {
  comment: IComment
}

export const CommentData = ({ comment }: Props) => {
  const CommentMetaData = useMemo(
    () => getFragmentData(CommentMetaData_Fragement, comment),
    [comment]
  )
  const { like, likeCount, toggleLike } = useToggleCommentLike(CommentMetaData)
  const [repliesVisible, setRepliesVisible] = useState(false)
  const isReply = !!CommentMetaData.parent_id
  const hasPeply = CommentMetaData && (CommentMetaData.replies ?? 0) > 0
  return (
    <div className="relative flex gap-3">
      <Link href={`/user/${CommentMetaData.user_id}`}>
        <UserAvatar avatarUrl={CommentMetaData.user.avatar_url ?? ''} className="h-7 w-7" />
      </Link>
      <div
        className="relative flex flex-1 flex-col gap-2 border-gray-200 pb-3.5"
        style={{ borderBottomWidth: isReply ? '$0' : '1px' }}
      >
        <p className="text-sm text-gray-800">{CommentMetaData.user.name}</p>
        <p>{CommentMetaData.comment}</p>
        <div className="relative mt-2.5 flex items-center justify-between">
          <p className="text-sm text-gray-800">
            <TimeAgo date={CommentMetaData.created_at} locale="en-US" />
          </p>
          <div className="relative flex items-center gap-4">
            <div className="relative flex items-center gap-2">
              <div className="flex" onClick={() => toggleLike()}>
                <LikeButton checked={like} className="h-3 w-3" />
              </div>
              {likeCount > 0 && <p className="font-gray-700 text-xs">{likeCount}</p>}
            </div>
            {!CommentMetaData.parent_id && (
              <div className="flex cursor-pointer items-center gap-2">
                <div
                  className="flex"
                  onClick={() => {
                    editor$.parentId.set(CommentMetaData.id)
                    editor$.replyingTo.set(comment)
                    editor$.focus.set(true)
                  }}
                >
                  <MessageCircle className="text-gray-800" size={12} />
                </div>
                {CommentMetaData && (CommentMetaData.replies ?? 0) > 0 && (
                  <p className="font-gray-800 text-xs">{CommentMetaData.replies}</p>
                )}
              </div>
            )}
          </div>
        </div>
        {!repliesVisible && hasPeply && (
          <div className="flex">
            <p
              className="font-gray-600 cursor-pointer text-xs"
              onClick={() => setRepliesVisible(true)}
            >
              View Replies
            </p>
          </div>
        )}
        {repliesVisible && !isReply && (
          <div className="flex flex-col">
            <CommentList parentId={CommentMetaData.id} />
          </div>
        )}
      </div>
    </div>
  )
}
