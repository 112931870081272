import { useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { useErrorToast } from 'app/hooks/useToast'
import { useSupabase } from 'app/utils/supabase/useSupabase'

import { useTermsAndNewsletterCheck } from './useTermsAndNewsletterCheck'

export const useGoogleSignIn = () => {
  const loading$ = useObservable(false)

  const { toastError } = useErrorToast()
  const supabase = useSupabase()
  const { termsChecked$, shakeTerms, newsletterChecked$ } = useTermsAndNewsletterCheck()

  const onGoogleSignIn = useMemoizedFn(async ({ redirectTo }: { redirectTo?: string }) => {
    if (!termsChecked$.get()) {
      shakeTerms()
      return
    }
    loading$.set(true)
    let redirectUrl = `${location.origin}/api/auth/callback?signup_flow=true`
    if (redirectTo) {
      redirectUrl = `${redirectUrl}&redirect=${redirectTo}`
    }
    if (newsletterChecked$.get()) {
      redirectUrl = `${redirectUrl}&newsletter=true`
    }
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: redirectUrl,
      },
    })
    if (error) {
      toastError(error.message ?? 'Something went wrong')
    }
    loading$.set(false)
  })

  return {
    onGoogleSignIn,
    loading$,
  }
}
