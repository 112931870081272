import { observable } from '@legendapp/state'
import { useMemoizedFn } from 'ahooks'

const termsChecked$ = observable(true)
const newsletterChecked$ = observable(false)
const termsShake$ = observable(false)

export const useTermsAndNewsletterCheck = () => {
  const shakeTerms = useMemoizedFn(() => {
    termsShake$.set(true)
    setTimeout(() => {
      termsShake$.set(false)
    }, 1000)
  })

  return { termsChecked$, newsletterChecked$, termsShake$, shakeTerms }
}
