import { useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { useErrorToast } from 'app/hooks/useToast'
import { useSupabase } from 'app/utils/supabase/useSupabase'
export const useChangePassword = () => {
  const loading$ = useObservable(false)

  const supabase = useSupabase()
  const { toastError } = useErrorToast()

  const onChangePassword = useMemoizedFn(
    async ({ password, afterChange }: { password: string; afterChange?: () => void }) => {
      loading$.set(true)
      const { error } = await supabase.auth.updateUser({ password })
      if (error) {
        toastError(error.message ?? 'Something went wrong')
      } else {
        afterChange?.()
      }
      loading$.set(false)
    }
  )

  return {
    onChangePassword,
    loading$,
  }
}
