import { Observable } from '@legendapp/state'
import { observer, useObservable } from '@legendapp/state/react'
import { Drawer } from '@my/magic-ui/src'
import { DrawerContent, DrawerPortal } from '@my/magic-ui/src/components/Drawer'
import { useMemoizedFn } from 'ahooks'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { useCheckLogin } from 'app/features/auth/hooks/useCheckLogin'
import { useIsMobile } from 'app/hooks/useMediaQuery'
import { useErrorToast, useSuccessToast } from 'app/hooks/useToast'
import { api } from 'app/utils/api'

interface Props {
  open$: Observable<boolean>
  modelId?: string
  postId?: string
}

export const ReportModal = observer((props: Props) => {
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <Drawer
        open={props.open$.get()}
        onClose={() => props.open$.set(false)}
        onOpenChange={(v) => {
          if (!v) props.open$.set(false)
        }}
      >
        <DrawerPortal>
          <DrawerContent className="z-[9999]">
            <div className="px-4 py-6">
              <ReportContent {...props} />
            </div>
          </DrawerContent>
        </DrawerPortal>
      </Drawer>
    )
  }

  return (
    <Modal isOpen={props.open$.get()} onClose={() => props.open$.set(false)} mountToBody={false}>
      <div className="w-[80vw] max-w-[500px] p-6 pt-12">
        <ReportContent {...props} />
      </div>
    </Modal>
  )
})

const ReportContent = observer((props: Props) => {
  const reason$ = useObservable('')
  const { toastSuccess } = useSuccessToast()
  const { toastError } = useErrorToast()
  const { checkLogin } = useCheckLogin()
  const mutation = api.user.createReport.useMutation()

  const onConfirm = useMemoizedFn(async () => {
    try {
      if (!checkLogin(true)) {
        return
      }
      await mutation.mutateAsync({
        reason: reason$.get(),
        modelId: props.modelId,
        postId: props.postId,
      })
      toastSuccess('Reported successfully')
      props.open$.set(false)
    } catch (error) {
      toastError('Report failed')
    }
  })

  return (
    <div className="flex flex-col gap-4">
      <p className="text-xl font-bold">Report {props.modelId ? 'Model' : 'Creation'}</p>
      <div className="space-y-1">
        <p className="text-white/50">Reason</p>
        <textarea
          value={reason$.get()}
          onChange={(e) => {
            if (e.target.value.length <= 300) {
              reason$.set(e.target.value)
            }
          }}
          className="min-h-[150px] w-full cursor-pointer rounded-lg border border-gray-200 bg-[#DAD6C30D] p-4 hover:border-gray-300 focus:border-gray-400 focus:outline-none"
          placeholder="Please provide a detailed description of the content that you believe violates our community guidelines. Up to 300 characters."
        />
      </div>
      <div className="flex justify-end gap-4">
        <Button
          mode="transparent"
          showArrow={false}
          className="w-auto flex-1 py-3 text-base font-bold"
          onClick={() => props.open$.set(false)}
        >
          Cancel
        </Button>
        <Button
          mode="transparent"
          showArrow={false}
          className="w-auto flex-1 bg-[#FFC24C] py-3 text-base font-bold text-black"
          disabled={mutation.isLoading || reason$.get().length === 0}
          loading={mutation.isLoading}
          onClick={onConfirm}
        >
          Report
        </Button>
      </div>
    </div>
  )
})
