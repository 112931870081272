import { observer, useObserve, useSelector } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import Input from 'app/components/Input'
import { useCheckLogin } from 'app/features/auth/hooks/useCheckLogin'
import { XCircle } from 'lucide-react'
import { useEffect, useRef } from 'react'

import { useAddComment } from './hooks/useAddComment'
import { editor$, resetEditStore } from './store/editor'

export const CommentEditor = observer(
  ({ topic, onComment }: { topic: string; onComment?: () => void }) => {
    const ref = useRef<HTMLInputElement>(null)
    const edited = useSelector(() => !!editor$.value.get())
    const isReplying = useSelector(() => !!editor$.parentId.get() && !!editor$.replyingTo.get())
    const addComments = useAddComment()
    const { checkLogin } = useCheckLogin()

    useObserve(() => {
      if (editor$.focus.get()) {
        ref.current?.focus()
      }
    })

    useEffect(() => {
      resetEditStore()
      editor$.topic.set(topic)
    }, [topic])

    return (
      <div className="flex w-full items-center gap-3.5">
        <div className="flex flex-1 items-center justify-between gap-2 overflow-hidden rounded-xl bg-gray-200 pr-3.5">
          <Input
            onChange={(v) => editor$.value.set(v)}
            disabled={editor$.readOnly.get()}
            placeholder={editor$.placeholder.get()}
            value={editor$.value.get()}
            className="bg-transparent"
          />

          {(edited || isReplying) && (
            <div
              className="flex cursor-pointer"
              onClick={() => resetEditStore({ topic: editor$.topic.peek() })}
            >
              <XCircle className="text-gray-700" size={18} />
            </div>
          )}
        </div>
        <div
          className="flex overflow-hidden"
          style={{ width: edited ? 48 : 0, opacity: edited ? 1 : 0 }}
        >
          <Button
            mode="transparent"
            showArrow={false}
            className="bg-primary-active w-[48px] border-none py-2 text-xs text-white"
            loading={addComments.isLoading}
            onClick={() => {
              if (checkLogin()) {
                addComments.mutate()
                onComment?.()
              }
            }}
          >
            Send
          </Button>
        </div>
      </div>
    )
  }
)
