import { observer } from '@legendapp/state/react'
import { Drawer, DrawerContent, DrawerPortal, DrawerTitle } from '@my/magic-ui/src'
import { Image } from 'app/components/Image'
import { AuthBack, homeLayout$, homeLayoutStore } from 'app/features/home/stores/homeLayout'

import { AuthContent } from './AuthContent'
import { BackButton } from './BackButton'
import { CloseButton } from './CloseButton'

export const MobileAuthDrawer = observer(() => {
  return (
    <Drawer open={homeLayout$.auth.show.get()} onClose={homeLayoutStore.closeAuthModal}>
      <DrawerPortal>
        <DrawerContent className="z-[9999] border-none">
          <DrawerTitle />
          <div className="no-scrollbar relative h-[100vh] h-dvh w-screen overflow-y-auto bg-gray-100">
            <div className="relative z-50 px-4 pt-6">
              <div className="flex items-center justify-between">
                {homeLayout$.auth.showBack.get() ? (
                  <BackButton onClick={() => AuthBack[homeLayout$.auth.state.get()]?.()} />
                ) : (
                  <div className="w-10" />
                )}
                <CloseButton onClick={homeLayoutStore.closeAuthModal} />
              </div>
              <div className="bg-[#1E1E1EB2]/60">
                <AuthContent />
              </div>
            </div>

            <Image
              webpSrc="https://cdn.moggcontent.com/images/m83v4omkwkz0cm7t.webp"
              fallbackSrc="https://cdn.moggcontent.com/images/m83v4zy37l9kx3g8.png"
              alt=""
              className="absolute inset-0 z-0 h-full w-full object-cover object-top"
            />
          </div>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  )
})
