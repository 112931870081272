import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.24645 9.35866C7.88187 9.35866 7.59359 9.64199 7.59359 10.0003C7.59359 10.3503 7.88187 10.642 8.24645 10.642H13.3337V14.6253C13.3337 16.667 11.6464 18.3337 9.56065 18.3337H5.43153C3.35425 18.3337 1.66699 16.6753 1.66699 14.6337V5.37533C1.66699 3.32533 3.36273 1.66699 5.44001 1.66699H9.5776C11.6464 1.66699 13.3337 3.32533 13.3337 5.36699V9.35866H8.24645ZM16.3588 7.11716L18.7922 9.54216C18.9172 9.66716 18.9838 9.82549 18.9838 10.0005C18.9838 10.1672 18.9172 10.3338 18.7922 10.4505L16.3588 12.8755C16.2338 13.0005 16.0672 13.0672 15.9088 13.0672C15.7422 13.0672 15.5755 13.0005 15.4505 12.8755C15.2005 12.6255 15.2005 12.2172 15.4505 11.9672L16.7838 10.6422H13.3338V9.35883H16.7838L15.4505 8.03383C15.2005 7.78383 15.2005 7.37549 15.4505 7.12549C15.7005 6.86716 16.1088 6.86716 16.3588 7.11716Z"
      fill="currentColor"
    />
  </svg>
)
export const IconLogout = memo(SvgComponent)
