import * as React from 'react'
import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      fill="#DCD8FF"
      d="m36.608 17.945-7.031 6.067 2.142 9.074a2.563 2.563 0 0 1-3.828 2.784L20 31.014l-7.895 4.856a2.564 2.564 0 0 1-3.824-2.784l2.15-9.073L3.4 17.944a2.572 2.572 0 0 1 1.456-4.508l9.219-.743 3.556-8.607a2.557 2.557 0 0 1 4.73 0l3.555 8.607 9.218.743a2.572 2.572 0 0 1 1.464 4.51l.01-.002Z"
    />
  </svg>
)
export const IconFree = memo(SvgComponent)
