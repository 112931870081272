import { ActionIcon } from 'app/components/ActionIcon'
import { ArrowLeft } from 'lucide-react'

export const BackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <ActionIcon
      icon={<ArrowLeft className="h-4 w-4" />}
      square
      className="w-auto bg-transparent active:bg-[#FFC24C]/20 active:text-[#FFC24C]"
      onClick={onClick}
    />
  )
}
