import { observable } from '@legendapp/state'

export const globalPost$ = observable({
  likes: [] as string[],
  dislikes: [] as string[],
})

export const addGlobalPostLike = (id: string) => {
  const likes = globalPost$.likes.get()
  const dislikes = globalPost$.dislikes.get()
  if (!likes.includes(id)) {
    globalPost$.likes.push(id)
  }
  if (dislikes.includes(id)) {
    globalPost$.dislikes.splice(
      dislikes.findIndex((d) => d === id),
      1
    )
  }
}

export const addGlobalPostDislike = (id: string) => {
  const likes = globalPost$.likes.get()
  const dislikes = globalPost$.dislikes.get()
  if (!dislikes.includes(id)) {
    globalPost$.dislikes.push(id)
  }
  if (likes.includes(id)) {
    globalPost$.likes.splice(
      likes.findIndex((d) => d === id),
      1
    )
  }
}

export const resetGLobalPostLike = (id: string) => {
  const likes = globalPost$.likes.get()
  const dislikes = globalPost$.dislikes.get()
  if (likes.includes(id)) {
    globalPost$.likes.splice(
      likes.findIndex((d) => d === id),
      1
    )
  }
  if (dislikes.includes(id)) {
    globalPost$.dislikes.splice(
      dislikes.findIndex((d) => d === id),
      1
    )
  }
}
