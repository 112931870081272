import { useComputed } from '@legendapp/state/react'
import { Subscription_Tier } from '@my/supabase/graphql/gql/graphql'
import { useUser } from 'app/utils/useUser'

export const useUserSubscribed = () => {
  const { userData$ } = useUser()
  const subscribed$ = useComputed(
    () => userData$.get() && userData$.subscription_tier.get() !== 'free'
  )

  return { subscribed$ }
}

export const useUserTier = () => {
  const { userData$ } = useUser()

  return userData$.subscription_tier.get() as Subscription_Tier
}
