import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { Button } from 'app/components/Button'

import { useGoogleSignIn } from '../hooks/useGoogleSignIn'

export const GoogleSignInButton = observer(
  ({ className, text, redirectTo }: { className?: string; text?: string; redirectTo?: string }) => {
    const { onGoogleSignIn, loading$ } = useGoogleSignIn()
    return (
      <Button
        mode="white"
        className={cn('pb-3 pt-2 font-bold text-black', className)}
        onClick={() => onGoogleSignIn({ redirectTo })}
        showArrow={false}
        disabled={loading$.get()}
        loading={loading$.get()}
      >
        {text || 'Sign in with Google'}
      </Button>
    )
  }
)
