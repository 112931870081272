import { observer, useMount, useObservable } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { getExploreModelLink, getExplorePostLink } from 'app/utils/model-version'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import { Compass, MessageCircle, Sparkles, User2 } from 'lucide-react'
import { useRouter as useNextRouter } from 'next/router'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PlayTabItem } from './components/PlayTabItem'

export const MobileBottomTab = observer(() => {
  const [t] = useTranslation('home')
  const open$ = useObservable(false)
  const scrollDirection$ = useObservable<'up' | 'down'>('up')
  const containerRef = useRef<HTMLElement | null>(null)
  const [ready, setReady] = useState(false)

  useMount(() => {
    setTimeout(() => {
      const container = document.getElementById('J_ScrollContainer')
      if (container) {
        containerRef.current = container
        setReady(true)
      }
    }, 1000)
  })

  const { scrollY } = useScroll({
    container: ready ? containerRef : undefined,
  })

  useMotionValueEvent(scrollY, 'change', (current) => {
    const previous = scrollY.getPrevious() ?? 0
    const diff = current - previous
    scrollDirection$.set(diff > 0 ? 'down' : 'up')
  })

  return (
    <div
      className="bg-coal-400 fixed bottom-0 left-0 right-0 z-[99] flex justify-between gap-11 border-[1px] border-solid border-gray-200 px-2 pb-6 pt-3 backdrop-blur-xl md:hidden"
      style={{ opacity: scrollDirection$.get() === 'up' ? 1 : 0.6 }}
    >
      <div className="flex flex-1 justify-around">
        <TabItem icon={Compass} text={t('explore')} path={getExplorePostLink()} />
        <TabItem icon={Sparkles} text={t('model')} path={getExploreModelLink()} />
      </div>
      <div className="flex flex-1 justify-around">
        <TabItem icon={MessageCircle} text={t('chat')} path="/chat" />
        <TabItem icon={User2} text={t('profile')} path="/profile" />
      </div>

      <PlayTabItem open$={open$} />
    </div>
  )
})

const TabItem = ({
  icon: Icon,
  text,
  path,
}: {
  icon: React.ElementType
  text: string
  path: string
}) => {
  const router = useNextRouter()
  const isActive = router.pathname === path
  return (
    <div
      className="flex flex-col items-center gap-1.5"
      style={{
        scale: isActive ? 1 : 0.9,
      }}
      onClick={() => router.push(path)}
    >
      <Icon className={cn('h-6 w-6', isActive ? 'text-primary-active' : 'text-gray-900')} />
      <p
        className={cn(
          'text-[13px] font-semibold',
          isActive ? 'text-primary-active' : 'text-gray-900'
        )}
      >
        {text}
      </p>
    </div>
  )
}
