import { SVGProps, memo } from 'react'
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1162 2.5C15.2337 2.5 16.3087 2.94167 17.0995 3.73417C17.8912 4.525 18.3337 5.59167 18.3337 6.70833V13.2917C18.3337 15.6167 16.442 17.5 14.1162 17.5H5.88366C3.55783 17.5 1.66699 15.6167 1.66699 13.2917V6.70833C1.66699 4.38333 3.54949 2.5 5.88366 2.5H14.1162ZM15.442 7.95L15.5087 7.88333C15.7078 7.64167 15.7078 7.29167 15.4995 7.05C15.3837 6.92583 15.2245 6.85 15.0587 6.83333C14.8837 6.82417 14.717 6.88333 14.5912 7L10.8337 10C10.3503 10.4008 9.65783 10.4008 9.16699 10L5.41699 7C5.15783 6.80833 4.79949 6.83333 4.58366 7.05833C4.35866 7.28333 4.33366 7.64167 4.52449 7.89167L4.63366 8L8.42533 10.9583C8.89199 11.325 9.45783 11.525 10.0503 11.525C10.6412 11.525 11.217 11.325 11.6828 10.9583L15.442 7.95Z"
      fill="currentColor"
    />
  </svg>
)
export const IconContact = memo(SvgComponent)
