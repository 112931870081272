import { observer, useObservable } from '@legendapp/state/react'
import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { X } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { useGeneratedImgWithOP } from '../context/GeneratedImgWithOPContext'

export const DeleteButton = observer(() => {
  const open$ = useObservable(false)

  const [t] = useTranslation('generateImage', { keyPrefix: 'deleteButton' })
  const { onDelete } = useGeneratedImgWithOP()

  return (
    <>
      <div>
        <ActionIcon
          icon={<X className="h-4 w-4" />}
          square
          className="bg-white/5"
          onClick={() => open$.set(true)}
        />
      </div>
      <Modal isOpen={open$.get()} onClose={() => open$.set(false)} showCloseButton={false}>
        <div className="relative flex w-[100vw] max-w-[400px] flex-col gap-3 p-4">
          <p className="text-2xl font-bold text-white">{t('title')}</p>
          <p className="text-white/80">{t('desc')}</p>
          <div className="flex flex-col gap-3">
            <Button
              mode="white"
              onClick={() => {
                open$.set(false)
                onDelete?.()
              }}
              showArrow={false}
              className="py-3 text-black"
            >
              {t('confirm')}
            </Button>
            <Button
              mode="transparent"
              onClick={() => open$.set(false)}
              showArrow={false}
              className="py-3"
            >
              {t('cancel')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
})
