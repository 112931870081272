import { observer } from '@legendapp/state/react'
import { cn } from '@my/magic-ui/src'
import { useMemoizedFn } from 'ahooks'
import { Button } from 'app/components/Button'
import { useForm } from 'react-hook-form'

import { FormField } from './components/FormField'
import { GoogleSignInButton } from './components/GoogleSignInButton'
import { TermsAndNewsletter } from './components/TermsAndNewsletter'
import { useSignUp } from './hooks/useSignUp'

interface SignUpFormProps {
  className?: string
  onSignIn: () => void
  afterSignUp: ({ email, newsletter }: { email: string; newsletter: boolean }) => void
  googleRedirectTo?: string
}

interface FormData {
  email: string
  password: string
}

export const SignUpForm = observer(
  ({ className, onSignIn, afterSignUp, googleRedirectTo }: SignUpFormProps) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
    } = useForm<FormData>({
      defaultValues: {
        email: '',
        password: '',
      },
    })
    const { onSignUp, loading$ } = useSignUp()

    const onSubmit = useMemoizedFn(async ({ email, password }: FormData) => {
      onSignUp({ email, password, setError, afterSignUp })
    })

    return (
      <div className={cn('font-grotesque px-6 py-8', className)}>
        <p className="font-grotesque text-center text-3xl font-bold">Create your account</p>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-2">
          <FormField
            label="Email"
            type="email"
            placeholder="Enter your email"
            error={errors.email}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email address',
              },
            })}
          />
          <FormField
            label="Password"
            type="password"
            placeholder="Enter your password"
            error={errors.password}
            {...register('password', {
              required: 'Password is required',
              minLength: { value: 6, message: 'Password must be at least 6 characters' },
            })}
          />
        </form>
        <Button
          className="font-grotesque mt-4 w-full bg-[#FFC24C] pb-4 pt-3 text-xl font-bold text-black"
          showArrow={false}
          mode="transparent"
          disabled={loading$.get()}
          loading={loading$.get()}
          onClick={handleSubmit(onSubmit)}
        >
          Sign up
        </Button>
        <p className="font-grotesque mt-3 text-lg font-bold text-white">
          Already have an account?{' '}
          <span className="cursor-pointer text-[#FFC24C] underline" onClick={onSignIn}>
            Sign in
          </span>
        </p>
        <p className="mt-4 text-center font-bold text-white/40">OR</p>
        <GoogleSignInButton
          className="mt-4"
          text="Sign up with Google"
          redirectTo={googleRedirectTo}
        />
        <TermsAndNewsletter className="mt-4" />
      </div>
    )
  }
)
