import { Switch, observer } from '@legendapp/state/react'
import { ChangePasswordForm } from 'app/features/auth/components/ChangePasswordForm'
import { ForgetPasswordForm } from 'app/features/auth/components/ForgetPasswordForm'
import { SignInForm } from 'app/features/auth/components/SignInForm'
import { SignUpForm } from 'app/features/auth/components/SignUpForm'
import { VerifyCodeForm } from 'app/features/auth/components/VerifyCodeForm'
import { AuthState, homeLayout$ } from 'app/features/home/stores/homeLayout'
import { parseAsBoolean, parseAsString, useQueryState } from 'nuqs'

const className = 'py-8'

export const AuthContent = observer(() => {
  const [email, setEmail] = useQueryState('email', parseAsString)
  const [newsletter, setNewsletter] = useQueryState('newsletter', parseAsBoolean)
  return (
    <Switch value={homeLayout$.auth.state.get()}>
      {{
        [AuthState.SIGN_IN]: () => (
          <SignInForm
            afterSignIn={() => homeLayout$.auth.show.set(false)}
            googleRedirectTo={location.href}
            onForgotPassword={() => homeLayout$.auth.state.set(AuthState.FORGET_PASSWORD)}
            onSignUp={() => homeLayout$.auth.state.set(AuthState.SIGN_UP)}
            className={className}
          />
        ),
        [AuthState.SIGN_UP]: () => (
          <SignUpForm
            afterSignUp={({ email, newsletter }) => {
              setEmail(email)
              setNewsletter(newsletter)
              homeLayout$.auth.state.set(AuthState.VERIFY_CODE)
            }}
            onSignIn={() => homeLayout$.auth.state.set(AuthState.SIGN_IN)}
            googleRedirectTo={location.href}
            className={className}
          />
        ),
        [AuthState.VERIFY_CODE]: () => (
          <VerifyCodeForm
            email={email ?? ''}
            newsletter={!!newsletter}
            onResendCode={() => homeLayout$.auth.state.set(AuthState.SIGN_IN)}
            redirectTo={location.href}
            className={className}
          />
        ),
        [AuthState.CHANGE_PASSWORD]: () => (
          <ChangePasswordForm
            afterChange={() => homeLayout$.auth.show.set(false)}
            className={className}
          />
        ),
        [AuthState.FORGET_PASSWORD]: () => (
          <ForgetPasswordForm
            onSignIn={() => homeLayout$.auth.state.set(AuthState.SIGN_IN)}
            className={className}
          />
        ),
        default: () => null,
      }}
    </Switch>
  )
})
