import { useScroll } from 'ahooks'
import { ActionIcon } from 'app/components/ActionIcon'
import { Button } from 'app/components/Button'
import Modal from 'app/components/Modal'
import { homeLayoutStore } from 'app/features/home/stores/homeLayout'
import { useUser } from 'app/utils/useUser'
import useViewportState from 'beautiful-react-hooks/useViewportState'
import { X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const SignupMasker = () => {
  const [t] = useTranslation('home', { keyPrefix: 'signupMasker' })
  const [open, setOpen] = useState(false)
  const [opened, setOpened] = useState(false)
  const { isLogin, isLoadingSession } = useUser()
  const scrollPosition = useScroll(document.getElementById('J_ScrollContainer') ?? document.body)
  const { height } = useViewportState()

  useEffect(() => {
    if (!isLogin && scrollPosition && scrollPosition.top > height && !opened) {
      setOpen(true)
      setOpened(true)
    }
  }, [height, isLogin, opened, scrollPosition, scrollPosition?.top])

  if (isLogin || isLoadingSession) return null

  return (
    <Modal isOpen={open} onClose={() => ({})} showCloseButton={false}>
      <div className="relative flex flex-col gap-4 p-4 text-center md:gap-6 md:p-6">
        <p className="text-center text-2xl font-bold text-[#FFC24C]/90">{t('title')}</p>
        <p className="text-gray-900 md:text-lg">{t('desc')}</p>
        <Button
          className="bg-[#FFC24C] py-2 text-base font-bold text-black"
          onClick={() => {
            homeLayoutStore.openSignInModal()
            setOpen(false)
          }}
          showArrow={false}
          mode="white"
        >
          {t('btnText')}
        </Button>
        <p className="text-gray-600">{t('hint')}</p>
        <ActionIcon
          icon={<X className="h-5 w-5" />}
          square
          className="absolute right-2 top-2"
          onClick={() => setOpen(false)}
        />
      </div>
    </Modal>
  )
}
