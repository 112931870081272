import { observer } from '@legendapp/state/react'
import { Image } from 'app/components/Image'
import Modal from 'app/components/Modal'
import { AuthBack, homeLayout$, homeLayoutStore } from 'app/features/home/stores/homeLayout'
import { IconFullName } from 'app/icons/IconFullName'

import { AuthContent } from './AuthContent'
import { BackButton } from './BackButton'
import { CloseButton } from './CloseButton'
export const WebAuthModal = observer(() => {
  return (
    <Modal
      isOpen={homeLayout$.auth.show.get()}
      onClose={() => {}}
      showCloseButton={false}
      bodyClassName="bg-[#1E1E1EB2]/60"
      backLayerClassName="bg-black/70"
    >
      <div className="relative z-[999999] flex min-h-[649px] w-[90.4vw] max-w-[826px] flex-col rounded-lg">
        <div className="flex w-[49%] flex-1 items-center justify-between bg-[#1E1E1EB2]/60">
          <div className="relative z-50 px-4 pt-6">
            {homeLayout$.auth.showBack.get() && (
              <div className="flex">
                <BackButton onClick={() => AuthBack[homeLayout$.auth.state.get()]?.()} />
              </div>
            )}
            <AuthContent />
          </div>
        </div>
        <Image
          webpSrc="https://cdn.moggcontent.com/images/m83v2aug5oi76e8y.webp"
          fallbackSrc="https://cdn.moggcontent.com/images/m83v2kf3hirl9xfg.png"
          alt=""
          className="absolute inset-0 z-0 h-full w-full rounded-lg object-cover object-top"
        />
        <div className="absolute right-5 top-5">
          <CloseButton onClick={homeLayoutStore.closeAuthModal} />
        </div>
        <div className="absolute bottom-6 right-[10%]">
          <IconFullName className="w-60" />
        </div>
      </div>
    </Modal>
  )
})
