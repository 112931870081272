import { observer } from '@legendapp/state/react'
import { Button } from 'app/components/Button'
import { useTranslation } from 'react-i18next'

import { IconGenerateMore } from './IconGenerateMore'
import { useGenerateMore } from './hooks/useGenerateMore'

export const GenerateMoreSlide = observer(() => {
  const [t] = useTranslation('postDetail', { keyPrefix: 'generateMore' })
  const { onGenerateMore } = useGenerateMore()
  return (
    <div className="-mt-12 flex w-full flex-1 flex-col items-center justify-center">
      <IconGenerateMore className="h-24 w-24 text-gray-800" />
      <Button
        mode="white"
        showArrow={false}
        onClick={onGenerateMore}
        className="mt-6 w-auto px-4 py-2 font-bold text-black"
      >
        {t('btnText')}
      </Button>
      <p className="mt-3 text-gray-800">{t('hint')}</p>
    </div>
  )
})
