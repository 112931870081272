import { observable } from '@legendapp/state'
import { IUserTag } from 'app/features/tags/typings/tag'

export const modelPlayStore = {
  state$: observable({
    generateMore: {
      customizePrompt: '',
      userTags: [] as IUserTag[],
    },
  }),

  reset: () => {
    modelPlayStore.state$.generateMore.customizePrompt.set('')
    modelPlayStore.state$.generateMore.userTags.set([])
  },
}
