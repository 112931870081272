import { observer } from '@legendapp/state/react'
import { ModelCreateButton } from 'app/features/models/ModelCreate/ModelCreateButton/ModelCreateButton'
import { DiscordLink } from 'app/features/socialMedia/DiscordLink/DiscordLink'

import { SearchButton } from './SearchButton'
import { UpgradeMenu } from './UpgradeMenu'
import { UserMenu } from './UserMenu/index'
import { homeLayout$ } from '../../stores/homeLayout'
import { WEB_HEADER_HEIGHT } from '../constants/layoutToken'

export const WebHeader = observer(() => {
  return (
    <div
      className="border-coal-100 bg-coal-100/80 transition-left fixed right-0 top-0 z-50 flex items-center border-b-2 border-solid px-4 text-lg font-semibold text-gray-900 backdrop-blur-[12px] duration-200"
      style={{
        height: WEB_HEADER_HEIGHT,
        left: homeLayout$.webSidebarWidth.get(),
      }}
    >
      <div className="flex w-full items-center justify-between gap-5 px-4">
        <div></div>
        <div className="flex items-center gap-5">
          <ModelCreateButton />
          <div className="flex items-center gap-2">
            <DiscordLink />
            <UpgradeMenu />
            <SearchButton />
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  )
})
