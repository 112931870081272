import { useObservable } from '@legendapp/state/react'
import { useMemoizedFn } from 'ahooks'
import { useErrorToast } from 'app/hooks/useToast'
import { api } from 'app/utils/api'
import { useSupabase } from 'app/utils/supabase/useSupabase'
import { UseFormSetError } from 'react-hook-form'

import { useTermsAndNewsletterCheck } from './useTermsAndNewsletterCheck'

export const useSignUp = () => {
  const loading$ = useObservable(false)

  const supabase = useSupabase()
  const { termsChecked$, shakeTerms, newsletterChecked$ } = useTermsAndNewsletterCheck()
  const { toastError } = useErrorToast()

  const deletedMutation = api.user.deleted.useMutation()

  const onSignUp = useMemoizedFn(
    async ({
      email,
      password,
      setError,
      afterSignUp,
    }: {
      email: string
      password: string
      setError: UseFormSetError<{ email: string; password: string }>
      afterSignUp: ({ email, newsletter }: { email: string; newsletter: boolean }) => void
    }) => {
      if (!termsChecked$.get()) {
        shakeTerms()
        return
      }
      loading$.set(true)
      const deleted = await deletedMutation.mutateAsync({ email })
      if (deleted) {
        toastError('Email already deleted')
        setError('email', { type: 'custom', message: 'Email already deleted' })
        loading$.set(false)
        return
      }
      const { error } = await supabase.auth.signUp({
        email,
        password,
      })
      if (error) {
        toastError(error.message ?? 'Something went wrong')
      } else {
        afterSignUp({ email, newsletter: newsletterChecked$.get() })
      }
      loading$.set(false)
    }
  )

  return {
    onSignUp,
    loading$,
  }
}
